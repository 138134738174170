import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Menu, MenuItem, TablePagination } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

import { fetchAllClients, checkClientExists, addClient, updateClient } from '../../dbfunc/ClientMastDbFunc'; // Ensure this path is correct

function ClientMastListData({ onMenuClicked }) {
  const [clients, setClients] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    const result = await fetchAllClients();
    if (result.success) {
      setClients(result.clients);
    } else {
      console.error("Error fetching clients:", result.error);
    }
  };

  const handleDelete = async (client) => {
    console.log('Delete clicked', client);
    setSelectedClient(client);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedClient && selectedClient.id) {
     // const docRef = doc(db, "clientmast", selectedClient.id);
      // await deleteDoc(docRef);
      setOpenDialog(false);
      fetchClients(); // Refresh list after deletion
    }
  };
  const handleRowClick = (client) => {
    console.log('Row clicked:', client);
    setSelectedClient(client);
  }
  const handleMenuClick = (feature) => {
    console.log('Menu clicked:', feature, selectedClient);
    if (feature === 'edit') {
      onMenuClicked('edit', selectedClient);
    } else if (feature === 'disable') {
      onMenuClicked('disable', selectedClient);
    } else if (feature === 'features') {
      onMenuClicked('features', selectedClient);
    } else if (feature === 'add') {
      onMenuClicked('add', null);
    }
  }

  return (
    <>
      <div>Client Master </div>
      <TableContainer component={Paper} sx={{ maxHeight: '650px', overflowY: 'auto' }}>
        <Table sx={{ minWidth: 650 }} aria-label="client master table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'blue' }}>
              {["Client ID", "Client Name", "Country", "Enabled", "Team Code"].map((header) => (
                <TableCell key={header} align="center" sx={{ color: 'white', fontWeight: 'bold' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client) => (
              <TableRow
                key={client.id}
                sx={{ '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                hover
                style={{ cursor: 'pointer' }}
              >
                <TableCell component="th" scope="row">{client.clientid}</TableCell>
                <TableCell>{client.client_name}</TableCell>
                <TableCell>{client.client_country}</TableCell>
                <TableCell>{client.enabled}</TableCell>
                <TableCell>{client.teamcode}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedClient(client);
                  }}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem onClick={() => handleMenuClick('edit')}>Edit</MenuItem>
                    <MenuItem onClick={() => handleMenuClick('disable')}>Disable</MenuItem>
                    <MenuItem onClick={() => handleMenuClick('features')}>Features</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageFeatures={[5, 10, 25, { label: 'All', value: -1 }]}
          component="div"
          count={clients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0); // Reset page to zero when changing the number of rows per page
          }}
          showLastButton={true}
          showFirstButton={true}
          sx={{ mr: '20px', display: 'flex', justifyContent: 'space-between' }}
        />
      </TableContainer>
      <Fab color="primary" aria-label="refresh" sx={{ position: 'fixed', bottom: 16, left: 16 }} onClick={()=>fetchClients()}>
        <RefreshIcon />
      </Fab>
      <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => onMenuClicked('add', null)}>
        <AddIcon />
      </Fab>
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message={`Are you sure you want to delete this client: ${selectedClient?.client_name}?`}
      />
    </>
  );
}

export default ClientMastListData;
