import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchAllAppTypes } from '../../dbfunc/AppTypesDbFunc';

function AppTypeDialog({ open, onClose, onSelect }) {
    const [appTypes, setAppTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (open) {
            setLoading(true);
            const fetchData = async () => {
                const response = await fetchAllAppTypes();
                if (response.success) {
                    setAppTypes(response.appTypes);
                } else {
                    console.error(response.error);
                }
                setLoading(false);
            };
            fetchData();
        }
    }, [open]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{height:800}}>
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6">Select an App Type</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
              Close
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ height: 'fit-content' }}>
          <TextField
            fullWidth
            label="Search App Types"
            variant="outlined"
            margin="dense"
            onChange={handleSearchChange}
          />
          {loading ? <p>Loading...</p> :
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Type Code</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appTypes.length > 0 ? appTypes.filter(apptype =>
                  Object.values(apptype).some(value =>
                    value.toString().toLowerCase().includes(searchQuery)
                  )).map((apptype) => (
                    <TableRow hover key={apptype.id} onClick={() => onSelect(apptype)}>
                      <TableCell style={{ minHeight: 60 }}>{apptype.des}</TableCell>
                      <TableCell>{apptype.apptype}</TableCell>
                      <TableCell>{apptype.enabled === "Y"? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  )) : <TableRow>
                    <TableCell colSpan={2} align="center">No matching app types found.</TableCell>
                  </TableRow>}
              </TableBody>
            </Table>}
        </DialogContent>
      </Dialog>
    );
}
export default AppTypeDialog;
