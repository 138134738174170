import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Menu, MenuItem, TablePagination } from '@mui/material';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import {CircularProgress, Box} from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {fetchAllServerApps,deleteAllServerAppFeatures,deleteServerApp } from '../../dbfunc/ServerAppDbFunc';
import { set } from 'firebase/database';
function ServerAppListData({ addClicked, onMenuClicked,serverdata }) {
  const [apps, setApps] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log('ServerAppListData Server data:', serverdata);
    fetchApps();
  }, [serverdata]);
  const fetchApps = async () => {
    setIsLoading(true);
    const alldocs = await fetchAllServerApps(serverdata.serverid);
    setIsLoading(false);
    if(alldocs.success){
        setApps(alldocs.apps);
        console.log('Apps:', alldocs.apps);
    } else {
        console.log('Error fetching apps:', alldocs.error);
    }
  };

  const handleConfirmDelete = async () => {
    console.log(' handleConfirmDelete', selectedApp);
    setSelectedApp(selectedApp);
    setOpenDialog(true);
  };
  const deleteRow = async () => {
    console.log('Delete row:', selectedApp);
    setOpenDialog(false);
    setIsLoading(true);
    const result = await deleteAllServerAppFeatures(serverdata.serverid,selectedApp.appcode);
    if(result.success){
       const result = await deleteServerApp(serverdata.serverid,selectedApp.appcode);
       if(result.success){
          fetchApps();
       } else {
          alert('Error deleting app ', result.error);
       }
      
    } else {
       alert('Error deleting app features ', result.error);
    }
    setIsLoading(false);
  }
  const handleMenuClick = (feature) => {
    console.log('Menu clicked:', feature);
    console.log('Menu clicked:', feature, selectedApp);
     if(feature === 'edit'){
        onMenuClicked('edit', selectedApp);
     } else if(feature === 'delete'){
        handleConfirmDelete();
     } else if(feature === 'features'){
        onMenuClicked('features', selectedApp);
     }
  };
  return (
    <>
      {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
      )}
    <TableContainer component={Paper}>
      <Table aria-label="app master table">
        <TableHead>
          <TableRow>
            {["appcode", "Enabled", "User Count", "License Type"].map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {apps.map((app) => (
            <TableRow key={app.id}
              style={{ cursor: 'pointer' }} 
            >               
              <TableCell>{app.appcode}</TableCell>
              <TableCell>{app.enabled}</TableCell>
              <TableCell>{app.user_count}</TableCell>
              <TableCell>{app.license_type}</TableCell>
              {/* More fields and possibly action buttons */}
              <TableCell>
                  <IconButton onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedApp(app);
                  }}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem onClick={() => handleMenuClick('edit')}>Edit</MenuItem>
                    <MenuItem onClick={() => handleMenuClick('delete')}>Delete</MenuItem>
                    <MenuItem onClick={() => handleMenuClick('features')}>Features</MenuItem>
                  </Menu>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Fab color="primary" aria-label="refresh" sx={{ position: 'fixed', bottom: 16, left: 16 }} onClick={() => fetchApps()}>
        <RefreshIcon />
      </Fab>      
      <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => onMenuClicked('add', null)}>
        <AddIcon />
      </Fab>
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={deleteRow}
        title="Confirm Deletion"
        message={`Are you sure you want to delete this app type: ${selectedApp?.appcode}?`}
      />
      
    </>
  );
}

export default ServerAppListData;
