import React, { useState, useEffect } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, Box,Select,MenuItem,InputLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { addServerApp,updateServerApp,checkServerAppExists } from '../../dbfunc/ServerAppDbFunc';   
import { addServerAppFeature } from '../../dbfunc/ServerAppFeaturesDbFunc';
import { fetchAllAppFeatures } from '../../dbfunc/AppFeaturesDbFunc';
import AppCodeLookup from '../Apps/AppCodeLookup';
import { formatDate } from '../../dbfunc/genfunc';
import { UserContext } from '../../providers/UserContext';
import { useContext } from 'react';
import AuthTypeSelection from '../Common/AuthTypeSelection';
import AppInstallType from '../Common/AppInstallType';
import AppVerTypeSelect from '../Common/AppVerTypeSelect';

const lic_features = ['ONE-TIME', 'YEARLY', 'MONTHLY', 'DAILY', 'TRIAL', 'ONCE', 'CHILD', 'NONE'];

function ServerAppEntry({ backClicked, entryMode,serverid, appData,clientid }) {
    const isEditing = entryMode === "edit";
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [appFeatures, setAppFeatures] = useState([]);
    const { userData, setUserData } = useContext(UserContext);
  const [formData, setFormData] = useState({
    appcode: '',
    enableLiveSync: 'N',
    enabled: 'Y',
    grace_period: '',
    license_type: '',
    user_count: 0,
    entry_date : formatDate(new Date()),
    start_date: formatDate(new Date()),
    valid_until: '',
    install_type: 'VALIDATE',
    extra_users: 0,
    appData1: '',
    appData2: '',
    serverid: '',
    order_ref:'',
    created_by: '',
    edited_by: '',
    edited_date: '',
    clientid: '',
    authType:'NONE',
    enable_same_ip: 'N',
    verType: 'ENT',
  });

  useEffect(() => {
    console.log('Server App data', appData);
    console.log('serverid', serverid);
    if (entryMode === 'edit' && appData) {
      const newData = {
        appcode: appData.appcode || '',
        enableLiveSync: appData.enableLiveSync || 'N',
        enabled: appData.enabled || 'Y',
        grace_period: appData.grace_period || '',
        license_type: appData.license_type || '',
        user_count: appData.user_count || 0,
        entry_date: appData.entry_date || '',
        start_date: appData.start_date || '',
        valid_until: appData.valid_until || '',
        install_type: appData.install_type || 'N',
        extra_users: appData.extra_users || 0,
        appData1: appData.appData1 || '',
        appData2: appData.appData2 || '',
        serverid: appData.serverid || '',
        order_ref: appData.order_ref || '',
        created_by: appData.created_by || '',
        edited_by: appData.edited_by || '',
        edited_date: appData.edited_date || '',
        clientid : appData.clientid || '',
        authType : appData.authType || '',
        enable_same_ip: appData.enable_same_ip || 'N',
        verType: appData.verType || 'ENT',
      };
      if(appData.serverid ===   undefined ||  appData.serverid === ""){
        newData.serverid = serverid;
     }
     if(newData.clientid === undefined || newData.clientid === "") {
         newData.clientid = clientid
     }
     newData.edited_by = userData.userId;
     newData.edited_date = formatDate(new Date());
     setFormData(newData);
     console.log("adjusted app data ",newData)
    } else {
        setFormData({...formData, serverid: serverid, entry_date: formatDate(new Date()), created_by: userData.userId});        
    }
  }, [appData, entryMode]);

  const handleChange = (event) => {
    const { name, type, checked } = event.target;
    const value = type === 'checkbox' ? (checked ? 'Y' : 'N') : event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleAuthTypeChange = (value) => {
    console.log("authtype change ",value)
    setFormData({...formData,authType:value})
  }
  const installTypeChange = (value) => {
    console.log("Install type change ",value)
    setFormData({...formData,install_type:value})
  }
  const verTypeChange = (value) => {
    console.log("ver type change ",value)
    setFormData({...formData,verType:value})
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    try {
        if (formData.appcode === "" || formData.enabled === "") {
            alert("Please fill appcode,enabled the fields");
            return;
        }
        if (formData.serverid === "" || formData.serverid === undefined) {
            alert("Server ID is required");
            return;
        }
        if(formData.start_date === "" || formData.valid_until === "") {
            alert("Please fill all the date fields");
            return;
        }
        if(formData.license_type === "") {
            alert("Please select a license type");
            return;
        }
        if (formData.user_count === "")  {
            alert("Please fill user_count the fields");
            return;
        }    
        if(entryMode === 'edit'){
          formData.edited_by = 'Admin';
          formData.edited_date = formatDate(new Date());
        }
        if(formData.authType === "") {
           alert("auth enabled is emtpy")
           return
        }
        if(formData.verType === "") {
            alert("Version type is empty")
            return
        }
        setIsLoading(true);

        if (isEditing) {
            const updateResult = await updateServerApp(serverid, formData.appcode, formData);
            if (!updateResult.success) {
                alert('Failed to update the document. Please try again.');
                setIsLoading(false);
                return;
            }
            console.log('Document updated');
        } else {
            const checkResult = await checkServerAppExists(serverid, formData.appcode);
            if (!checkResult.success) {
                alert('Error checking document existence. Please try again.');
                setIsLoading(false);
                return;
            }
            if (checkResult.exists) {
                alert('App code already exists');
                setIsLoading(false);
                return;
            }
            const addResult = await addServerApp(serverid,formData.appcode, formData);
            if (!addResult.success) {
                alert('Failed to add new document. Please try again.');
                setIsLoading(false);
                return;
            }
        }
        const result = await updateServerAppFeatures(formData.appcode);
        setIsLoading(false);
        if (!result) {
            alert('Failed to update app features');
            return
        }
        handleBack(); // Assuming you have a method to handle going back
    } catch (e) {
        console.error('Unexpected error:', e);
        alert('An unexpected error occurred. Please try again.');
        setIsLoading(false);
    }
    setIsLoading(false);
};
    function handleBack() {
        backClicked();
    }
    const handleAppCodeSelect = (papp) => {
      console.log('Selected app type:', papp.appcode);
      setFormData({...formData, appcode: papp.appcode});
      setDialogOpen(false); // Close dialog after selection
    };  
  const updateServerAppFeatures = async (appcode) => {
    const resutls = await fetchAllAppFeatures(appcode);
    console.log('App features results:', resutls);
    var appotions = [];
    if(resutls.success){
      resutls.features.forEach((doc) => {
        appotions.push({ serverid: serverid, ...doc });
    });      
      /*
      for (const feature of resutls.features) {
        setAppFeatures((prev) => [...prev, feature]);
      }
      */
      console.log('App features:', appotions);
      if(appotions === undefined){
        appotions = [];
      }
      var cnt = 0
      if(appotions.length > 100){
        alert('Too many app features:', appotions.length);
        return
      }

      for(const feature of appotions){
        console.log("feature to add ",feature)
        var appfeaturekey = appcode + "_"+ feature.featureKey;
        if (feature.deleted === 'Y') {
            continue;
        }
        var odata = {
          appcode : feature.appcode,
          dataType : feature.dataType,
          featureKey : feature.featureKey,
          featureValue : feature.featureValue,
          required : feature.required,
          serverid : serverid,
          moreInfo: feature.moreInfo,
          appFeatureKey : appfeaturekey,
          enabled: feature.enabled,
          clientid :clientid,
          verType : feature.verType,
          featureGroup : feature.featureGroup,
      }  
        const updateResult = await addServerAppFeature(serverid, appcode,appfeaturekey, odata);
        if (!updateResult.success) {
          console.error('Failed to update app feature:', updateResult.error);
          return false
        }
        cnt++;
        if(cnt > 100){
          console.error('Too many app features:', cnt);
          break;
        }
      }
      if(cnt > 100){
         alert('Too many app features:', cnt);
      }
    } else {
      console.error('Error fetching app features:', resutls.error);
      return false
    }
    return true
  }
  return (
    <Box
    component="form"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '600px',  // Sets the maximum width of the form
      width: '100%',  // Use 100% width to make it responsive within the max-width
      mx: 'auto',  // Centers the Box component horizontally in the parent container
      my: 0,  // Featureal: adjust vertical margin if necessary
      '& .MuiTextField-root': { m: 1 },
      '& .MuiButton-root': { m: 1 },
    }}
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
  >
    App Entry for Client : {clientid} , Server id : {serverid}
    <TextField
      label="App Code"
      name="appcode"
      value={formData.appcode}
      onChange={handleChange}
      onClick={() => setDialogOpen(true)} // Open dialog on text field click
      readOnly
      fullWidth
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={formData.enableLiveSync === 'Y'}
          onChange={handleChange}
          name="enableLiveSync"
        />
      }
      label="Live Enabled"
    />

    <FormControlLabel
      control={
        <Checkbox
          checked={formData.enabled === 'Y'}
          onChange={handleChange}
          name="enabled"
        />
      }
      label="Enabled"
    />

       <InputLabel id="license-type-label">License Type</InputLabel>
            <Select
                labelId="license-type-label"
                id="license-type-select"
                name="license_type"
                value={formData.license_type} // Bind value to formData.license_type
                onChange={handleChange}
            >
                {lic_features.map(feature => (
                    <MenuItem key={feature} value={feature}>
                        {feature}
                    </MenuItem>
                ))}
            </Select>    
    <TextField
      label="User Count"
      name="user_count"
      type="number"
      value={formData.user_count}
      onChange={handleChange}
      fullWidth
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={formData.enable_same_ip === 'Y'}
          onChange={handleChange}
          name="enable_same_ip"
        />
      }
      label="enabled_same_ip"
    />

    <TextField
      label="Start Date"
      name="start_date"
      type="date"
      value={formData.start_date}
      onChange={handleChange}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
    <TextField
      label="Valid Until"
      name="valid_until"
      type="date"
      value={formData.valid_until}
      onChange={handleChange}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
    <TextField
      label="Grace Period"
      name="grace_period"
      value={formData.grace_period}
      onChange={handleChange}
      fullWidth
    />
        <AuthTypeSelection initialValue={formData.authType}  onChange={handleAuthTypeChange} />        

        <AppInstallType initialValue={formData.install_type}  onChange={installTypeChange} />        
        <AppVerTypeSelect initialValue={formData.verType}  onChange={verTypeChange} />
    <TextField
      label="Extra Users"
      name="extra_users"
      type="number"
      value={formData.extra_users}
      onChange={handleChange}
      fullWidth
    />
    <TextField
      label="App Data 1"
      name="appData1"
      value={formData.appData1}
      onChange={handleChange}
      fullWidth
    />
    <TextField
      label="App Data 2"
      name="appData2"
      value={formData.appData2}
      onChange={handleChange}
      fullWidth
    />
    <TextField
      label="Server ID"
      name="serverid"
      value={formData.serverid}
      onChange={handleChange}
      fullWidth
      InputProps={{
        readOnly: true,
      }}      
    />
    <TextField
      label="Order Ref"
      name="order_ref"
      value={formData.order_ref}
      onChange={handleChange}
      fullWidth
    />
    <TextField
      label="Entry Date"
      name="entry_date"
      type="date"
      value={formData.entry_date}
      onChange={handleChange}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
    <TextField
      label="Created By"
      name="created_by"
      value={formData.created_by}
      onChange={handleChange}
      fullWidth
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      label="Edited By"
      name="edited_by"
      value={formData.edited_by}
      onChange={handleChange}
      fullWidth
      InputProps={{
        readOnly: true,
      }}      
    />
    <TextField
      label="Edited Date"
      name="edited_date"
      type="date"
      value={formData.edited_date}
      onChange={handleChange}
      fullWidth
      InputProps={{
        readOnly: true,
      }}    
      InputLabelProps={{
        shrink: true,
      }}
    />
     <AppCodeLookup
          open={dialogOpen}
          onSelect={handleAppCodeSelect}
          onClose={() => setDialogOpen(false)}
        />        

         {isLoading && <CircularProgress />}
        <Button variant="contained" onClick={handleBack}>Back</Button>
        <Button variant="contained" type="submit">Submit</Button>
    </Box>
  );
}

export default ServerAppEntry;
