// firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDr3jMeRDW5NVfyspFTMgW2YCK6z2Re4b0",
  authDomain: "applicense-3b654.firebaseapp.com",
  databaseURL: "https://applicense-3b654.firebaseio.com",
  projectId: "applicense-3b654",
  storageBucket: "applicense-3b654.appspot.com",
  messagingSenderId: "989850419533",
  appId: "1:989850419533:web:6fe71c0953fedd5856f704"  

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export { db, auth };

// export const auth = getAuth(app);

