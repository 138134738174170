import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Menu, MenuItem, TablePagination } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {CircularProgress, Box} from '@mui/material';
import { fetchAllServerAppFeatures,deleteServerAppFeature } from '../../dbfunc/ServerAppFeaturesDbFunc';
function ServerAppFeaturesListData({ onMenuClicked ,serverid, appdata}) {
  const [appfeatures, setAppFeatures] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAppFeature, setSelectedAppFeature] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    console.log('AppFeaturesListData:', appdata);
    fetchAppFeaturesList();
  }, [appdata]);
  const fetchAppFeaturesList = async () => {
    setIsLoading(true);
    const alldocs = await fetchAllServerAppFeatures(serverid,appdata.appcode);
    console.log('fetchAllServerAppFeatures:', alldocs);
    if(alldocs.success){
      if(alldocs.appFeaturesData === undefined){
        console.log('No app features found');
        setAppFeatures([]);
      } else {
        setAppFeatures(alldocs.appFeaturesData);
      }
    } else {
      setAppFeatures([]);
      console.log('Error fetching app types:', alldocs.error);
    }
    setIsLoading(false);
    console.log('AppFeaturesListData:', appfeatures);
  };

  const handleDelete = async (appfeature) => {
    console.log('Delete clicked', appfeature);
    setSelectedAppFeature(appfeature);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    console.log('handleConfirmDelete selectedAppFeature', selectedAppFeature);
    setOpenDialog(false);
    setIsLoading(true);
    const result = await deleteServerAppFeature(appdata.appcode, selectedAppFeature.featureKey);
    if(result.success){
      fetchAppFeaturesList();
    }
  };
  const handleRowClick = (feature) => {
      console.log('Row clicked:', feature);
      setSelectedAppFeature(feature);
  }
  const handleMenuClick = (feature) => {
    console.log('Menu clicked:', feature, selectedAppFeature);
    if (feature === 'edit') {
      onMenuClicked('edit', selectedAppFeature);
    } else if (feature === 'delete') {
      handleDelete(selectedAppFeature);
    }
  }
  return (
    <>
      <div>App Features Master - {serverid} --  {appdata.appcode} </div>
        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        )}

      <TableContainer component={Paper} sx={{ maxHeight: '650px', overflowY: 'auto' }}>
        <Table sx={{ minWidth: 650 }} aria-label="feature master table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'purple' }}>
              {["featureKey","DataType", "featureValue","Group","Ver Type", "required"].map((header) => (
                <TableCell key={header} align="center" sx={{ color: 'white', fontWeight: 'bold' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {appfeatures.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((feature) => (
              <TableRow
                key={feature.id}
                sx={{ '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                hover
                style={{ cursor: 'pointer' }}
              >
                <TableCell component="th" scope="row">{feature.featureKey}</TableCell>
                <TableCell>{feature.dataType}</TableCell>
                <TableCell>{feature.featureValue}</TableCell>
                <TableCell>{feature.featureGroup}</TableCell>
                <TableCell>{feature.verType}</TableCell>
                <TableCell>{feature.required ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedAppFeature(feature);
                  }}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem onClick={() => handleMenuClick('edit', feature)}>Edit</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageFeatures={[5, 10, 25, { label: 'All', value: -1 }]}
          component="div"
          count={appfeatures.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0); // Reset page to zero when changing the number of rows per page
          }}
          showLastButton={true}
          showFirstButton={true}
          sx={{ mr: '20px', display: 'flex', justifyContent: 'space-between' }}          
        />
      </TableContainer>
      <Fab color="primary" aria-label="refresh" sx={{ position: 'fixed', bottom: 16, left: 16 }} onClick={() => fetchAppFeaturesList()}>
        <RefreshIcon />
      </Fab>      
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message={`Are you sure you want to delete this app type: ${selectedAppFeature?.featureKey}?`}
      />
    </>
  );
  
}

export default ServerAppFeaturesListData;


