import React, { useState, useEffect } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, Box,Select,MenuItem,InputLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { formatDate } from '../../dbfunc/genfunc';
import { addAppFeatures,updateAppFeatures,fetchAllAppFeatures,checkAppFeaturesExists } from '../../dbfunc/AppFeaturesDbFunc';
import FeatureVerTypeSelect from '../Common/FeatureVerTypeSelect';
import { set } from 'firebase/database';

function AppFeaturesEntry({ backClicked, entryMode,appcode, featureData }) {
    const isEditing = entryMode === "edit";
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    appcode: '',
    featureKey: '',
    featureValue: '',
    required: 'Y',
    dataType: 'string',
    verType: 'COM',
    moreInfo: '',
    enabled: 'Y',
    deleted: 'N',
    featureGroup: '',
  });

  useEffect(() => {
    console.log('features App data', featureData);
    console.log('appcode', appcode);
    if (entryMode === 'edit' && featureData) {
      const newData = {
        appcode: featureData.appcode || '',
        featureKey: featureData.featureKey || '',
        featureValue: featureData.featureValue || '',
        required: featureData.required || 'Y',
        dataType: featureData.dataType || '',
        moreInfo: featureData.moreInfo || '',
        enabled: featureData.enabled || 'Y',
        deleted: featureData.deleted || 'N',
        verType: featureData.verType || 'COM',
        featureGroup: featureData.featureGroup || '',
      };
      if(featureData.featureKey ===   undefined){
        newData.appcode = appcode;
     }
       setFormData(newData);
    } else {
        setFormData({...formData, appcode: appcode});        
    }
  }, [appcode, entryMode]);
  const featureVerTypeChange = (value) => {
    console.log('featureVerTypeChange:', value);
    setFormData({
      ...formData,
      verType: value,
    });
  };
  const handleChange = (event) => {
    const { name, type, checked } = event.target;
    const value = type === 'checkbox' ? (checked ? 'Y' : 'N') : event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDataTypeChange = (event) => {
    const { name, type,value } = event.target;
    console.log('handleDataTypeChange:', name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handleSubmit ",formData);
    try {
        if (formData.appcode === "" || formData.required === "") {
            alert("Please fill appcode,appcode the fields");
            return;
        }
        if(formData.featureKey === "" || formData.featureValue === ""){
          alert("Please fill all the fields");
          return;
        }
        setIsLoading(true);

        if (isEditing) {
            const updateResult = await updateAppFeatures(appcode, formData.featureKey, formData);
            if (!updateResult.success) {
                alert('Failed to update the document. Please try again.');
                setIsLoading(false);
                return;
            }
            console.log('Document updated');
        } else {
            const checkResult = await checkAppFeaturesExists(appcode, formData.featureKey);
            if (!checkResult.success) {
                alert('Error checking document existence. Please try again.');
                setIsLoading(false);
                return;
            }
            if (checkResult.exists) {
                alert('App code already exists');
                setIsLoading(false);
                return;
            }
            const addResult = await addAppFeatures(appcode,formData.featureKey, formData);
            if (!addResult.success) {
                alert('Failed to add new document. Please try again.');
                setIsLoading(false);
                return;
            }
        }
        setIsLoading(false);
        handleBack(); // Assuming you have a method to handle going back
    } catch (e) {
        console.error('Unexpected error:', e);
        alert('An unexpected error occurred. Please try again.');
        setIsLoading(false);
    }
    setIsLoading(false);
};
    function handleBack() {
        backClicked();
    }
  return (
    <Box
    component="form"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '600px',  // Sets the maximum width of the form
      width: '100%',  // Use 100% width to make it responsive within the max-width
      mx: 'auto',  // Centers the Box component horizontally in the parent container
      my: 0,  // Optional: adjust vertical margin if necessary
      '& .MuiTextField-root': { m: 1 },
      '& .MuiButton-root': { m: 1 },
    }}
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
  >
    <TextField
      label="App Code"
      name="appcode"
      value={formData.appcode}
      readOnly
      fullWidth
    />
    <FeatureVerTypeSelect initialValue={formData.verType} onChange={featureVerTypeChange} />
    <TextField
      label="Features Key"
      name="featureKey"
      type="text"
      value={formData.featureKey}
      onChange={handleChange}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
 <InputLabel>Data Type</InputLabel>
      <Select
        value={formData.dataType}
        onChange={handleChange}
        name="dataType"
      >
        <MenuItem value="string">String</MenuItem>
        <MenuItem value="number">Number</MenuItem>
        <MenuItem value="date">Date</MenuItem>
        <MenuItem value="json">JSON</MenuItem>
      </Select>    
    <TextField
      label="Feature Value"
      name="featureValue"
      type="text"
      value={formData.featureValue}
      onChange={handleChange}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
    <TextField
      label="Feature Group"
      name="featureGroup"
      type="text"
      value={formData.featureGroup}
      onChange={handleChange}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={formData.required === 'Y'}
          onChange={handleChange}
          name="enableLiveSync"
        />
      }
      label="Required"
    />
    <TextField
      label="More Info"
      name="moreInfo"
      type="text"
      value={formData.moreInfo}
      onChange={handleChange}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
    <TextField
      label="Enabled"
      name="enabled"
      type="text"
      value={formData.enabled}
      onChange={handleChange}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
    {isLoading && (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    )}
    <Button variant="contained" onClick={handleBack}>Back</Button>
    <Button variant="contained" onClick={handleSubmit} >Save</Button>
  </Box>
  );
}

export default AppFeaturesEntry;
