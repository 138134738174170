import { doc, setDoc, getDoc, getDocs, collection, deleteDoc, collectionGroup,where,query } from 'firebase/firestore';
import { db } from '../firebase-config'; // Adjust this import as necessary

// Function to fetch all app features for a specific serverid
export const fetchAllAppFeatures = async (appcode) => {
    try {
        // Path to the nested collection
        const serverAuthRef = doc(db, 'appcodes', appcode);
        const appFeaturesRef = collection(serverAuthRef, 'appfeatures');
        const querySnapshot = await getDocs(appFeaturesRef);
        let appFeatures = [];
        querySnapshot.forEach((doc) => {
            appFeatures.push({ id: doc.id, ...doc.data() });
        });
        if(appFeatures === undefined){
            appFeatures = [];
        } 
        return { success: true, features: appFeatures }; // Return an object with a success status and the array of app features
    } catch (error) {
        console.error("Error fetching app features:", error);
        return { success: false, error }; // Return error information for further handling
    }
};
// export const addServerApp = async (serverid, appcode, appData) => {

export const addAppFeatures = async (appcode,featuresKey, appFeaturesData) => {
    try {
        const appFeatureRef = doc(collection(db, 'appcodes', appcode, 'appfeatures'), featuresKey);
        await setDoc(appFeatureRef, appFeaturesData);
        return { success: true, message: 'App feature added successfully' };
    } catch (error) {
        console.error("Error adding app feature:", error);
        return { success: false, error };
    }
};


export const updateAppFeatures = async (appcode, featuresKey, appFeatureData) => {
    console.log('updateAppFeatures',appcode,featuresKey,appFeatureData)
    try {
        const appFeatureRef = doc(collection(db, 'appcodes', appcode, 'appfeatures'), featuresKey);
        await setDoc(appFeatureRef, appFeatureData, { merge: true });
        return { success: true, message: 'App feature updated successfully' };
    } catch (error) {
        console.error("Error updating app feature:", error);
        return { success: false, error };
    }
};

export const deleteAppFeature = async (appcode, featuresKey) => {
    try {
        const appFeatureRef = doc(collection(db, 'appcodes', appcode, 'appfeatures'), featuresKey);
        await deleteDoc(appFeatureRef);
        return { success: true, message: 'App feature deleted successfully' };
    } catch (error) {
        console.error("Error deleting app feature:", error);
        return { success: false, error };
    }
};

export const checkAppFeaturesExists = async (appcode, featuresKey) => {
    try {
        const appFeatureRef = doc(collection(db, 'appcodes', appcode, 'appfeatures'), featuresKey);
        const docSnap = await getDoc(appFeatureRef);
        return { exists: docSnap.exists(), success: true, message: docSnap.exists() ? 'App feature exists' : 'App feature does not exist' };
    } catch (error) {
        console.error("Error checking if app feature exists:", error);
        return { success: false, error, exists: false };
    }
};

export const checkFeaturesKeyUsed = async (appcode,featuresKeyToCheck) => {
    console.log("Checking if featuresKey exists:", featuresKeyToCheck);
        let q
        try {
            q = query(collectionGroup(db, "serverappfeatures"), where("serverFeatureKey", "==", featuresKeyToCheck));
            const querySnapshot = await getDocs(q);
        
            // Check if any documents exist
            return !querySnapshot.empty;
          } catch (error) {
            console.error("Error checking featuresKey usage:", error);
            return false;
          }        
        /*
        const q = query(collection(db, "serverappfeatures"), where("serverFeatureKey", "==", featuresKeyToCheck));
        const querySnapshot = await getDocs(q);
        // Check if any documents exist
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
        })        
        console.log("querySnapshot.empty",querySnapshot.empty);
        return !querySnapshot.empty;
        */
};


