import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import {fetchAllServerAuths} from '../../dbfunc/ServerAuthDbFunc'
import useAuthListStore from '../../store/AuthListStore';

function ServerIdLookup({ open, onClose, onSelect }) {
    const [serverList, setServerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const MAuthListData = useAuthListStore((state) => state.MAuthListData);

    useEffect(() => {
        if (open) {
            /*
            setLoading(true);
            const fetchData = async () => {
                const response = await fetchAllServerAuths();
                if (response.success) {
                    setServerList(response.serverauths);
                } else {
                    console.error(response.error);
                }
                setLoading(false);
            };
            fetchData();
            */
        }
    }, [open]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };
    const handleSelect = (appType) => {
        if(appType.enabled !== 'Y'){
            alert('App Type is not enabled');
            return;
        }
        onSelect(appType);
    }
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6">Select an App Type</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                        Close
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Search App Types"
                    variant="outlined"
                    margin="dense"
                    onChange={handleSearchChange}
                />
                {loading ? <p>Loading...</p> :
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Server Id</TableCell>
                            <TableCell>Client Id</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {MAuthListData.length > 0 ? MAuthListData.filter(server =>
                            Object.values(server).some(value =>
                                value.toString().toLowerCase().includes(searchQuery)
                            )).map((server) => (
                            <TableRow hover key={server.id} onClick={() => onSelect(server)}>
                                <TableCell>{server.serverid}</TableCell>
                                <TableCell>{server.clientid}</TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={2} align="center">No matching servers</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>}
            </DialogContent>
        </Dialog>
    );
}

export default ServerIdLookup;
