import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import ServerAppFeaturesEntry from './ServerAppFeaturesEntry';
import ServerAppFeaturesListData from './ServerAppFeaturesListData';

function ServerAppFeaturesMastList({serverid,appdata,backClicked}) {
  const [content, setContent] = useState('table');  // 'table' or 'info'
  const [appData, setAppData] = useState(appdata);
  const [featureData, setFeatureData] = useState(null);
  const [entryMode, setEntryMode] = useState('add');
  const addClicked = () => {
    setContent('entry');
  };
  useEffect(() => {
    console.log('app data:', appdata);
    setAppData(appdata);
  }, [appdata]);
  const handleMenuClick = (feature, app) => {
    console.log("appdata ",appData)
    console.log('Menu clicked in main window', feature, app);
    setContent(feature);
    if(feature === 'edit'){
      setFeatureData(app);
      setEntryMode('edit');
    } else {
      if(feature === 'table'){
        setContent('table');
        return;
      }
      if(appData.appcode === undefined){
            alert('Please select a app first');
            return;
      }
      if(appData.appcode == ''){
          alert('Please select a app first');
          return;
      }
      setEntryMode('add');
    }
  };
  function handleBack(){
    backClicked();
  }


  return (
    <>
      {content === 'table' ? (
        <div>
            <Button variant="contained" onClick={() => handleBack()}>  Back to App Code List </Button>
            <ServerAppFeaturesListData  onMenuClicked={handleMenuClick} serverid={serverid}   appdata={appData}/>
        </div>
      ) : content === 'add' || content === 'edit' ? (
        <ServerAppFeaturesEntry backClicked={handleMenuClick} serverid={serverid} appData={appData} entryMode={entryMode} featureData = {featureData} appcode={appData.appcode} />
      ) 
      : (
        <div style={{ padding: 20 }}>
          <h2>{content === 'features' ? 'Features Information' : 'Disabling Information'}</h2>
          <p>Details about {content}</p>
          <Button variant="contained" onClick={() => setContent('table')}>Back to Table</Button>
        </div>
      )}
    </>
  );
}

export default ServerAppFeaturesMastList;


