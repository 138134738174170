import React from 'react';
const withAuth = (WrappedComponent) => {
  return (props) => {
    const isAuthenticated = true; // Implement actual authentication logic here

    if (isAuthenticated) {
      return <WrappedComponent {...props} />;
    }

    return <div>You are not authorized to access this page.</div>;
  };
};

export default withAuth;
