import { doc, setDoc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase-config'; // Adjust this import as necessary

// Function to fetch all clients
export const fetchAllClients = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'clientmast'));
        let clients = [];
        querySnapshot.forEach((doc) => {
            clients.push({ id: doc.id, ...doc.data() });
        });
        return { success: true, clients };
    } catch (error) {
        console.error("Error fetching clients:", error);
        return { success: false, error: error };
    }
};

// Function to add a new client with a custom ID
export const addClient = async (clientId, formData) => {
    try {
        const docRef = doc(db, 'clientmast', clientId);
        await setDoc(docRef, formData);
        return { success: true, id: docRef.id };
    } catch (error) {
        console.error("Error adding new client:", error);
        return { success: false, error: error };
    }
};

// Function to update an existing client
export const updateClient = async (clientId, formData) => {
    try {
        const docRef = doc(db, 'clientmast', clientId);
        await setDoc(docRef, formData, { merge: true });
        return { success: true };
    } catch (error) {
        console.error("Failed to update client:", error);
        return { success: false, error: error };
    }
};

// Function to check if a client already exists
export const checkClientExists = async (clientId) => {
    console.log("Checking if client exists:", clientId);
    try {
        const docRef = doc(db, 'clientmast', clientId);
        const docSnap = await getDoc(docRef);
        return { exists: docSnap.exists(), success: true };
    } catch (error) {
        console.error("Failed to check if client exists:", error);
        return { exists: false, success: false, error: error };
    }
};
