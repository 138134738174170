
import React from 'react';

const HomePage = () => {
    return (
        <div>
        <h2>Home Page</h2>
        <p>Welcome to the Home Page</p>
        </div>
    );
}

export default HomePage;