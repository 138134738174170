import React, { useEffect, useState } from 'react';
import { Container, TextField, Checkbox, FormControlLabel, Button, Box } from '@mui/material';
import withAuth from '../../withAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../../firebase-config'; // Ensure this path is correct
import { doc, setDoc, addDoc, collection,query, where, getDocs  } from 'firebase/firestore';
import CircularProgress from '@mui/material/CircularProgress';
import {addClient,checkClientIdExists,updateClient} from '../../dbfunc/ClientMastDbFunc';
import { formatDate } from '../../dbfunc/genfunc';
const ClientMasterEntry = ({backClicked, entryMode, clientdata }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isEditing = entryMode === "edit";
  const clientData = clientdata ;
  const [isLoading, setIsLoading] = useState(false);
  const [penabled, setpEnabled] = useState(true);

    const [formData, setFormData] = useState({
      clientid: '',
      client_country: '',
      client_name: '',
      enabled: "Y",
      teamcode: '',
      createBy: '',
      createdDate: formatDate(new Date()),
      contactPerson: '',
      contactMailId: '',
      contactPhone: '',
    });
    useEffect(() => {
      console.log('Client data', clientData);
      console.log('isEditing', isEditing);
      var tempdata = {}
      Object.assign(tempdata, formData);
      if  (isEditing) {          
        tempdata.clientid = clientData.clientid
        tempdata.client_country  = clientData.client_country
        tempdata.client_name =  clientData.client_name
        tempdata.enabled =  clientData.enabled
        tempdata.teamcode =  clientData.teamcode
        if(clientData.enabled === "Y"){
          setpEnabled(true);
        }else{
          setpEnabled(false);
        }
        if(clientData.createBy === undefined){
          tempdata.createBy =  ""
        }else{
          tempdata.createBy =  clientData.createBy
        }
        if(clientData.createdDate === undefined){
          tempdata.createdDate =  ""
        }else{
          tempdata.createdDate =  clientData.createdDate
        }
        if(clientData.contactPerson === undefined){
          tempdata.contactPerson =  ""
        }else{
          tempdata.contactPerson =  clientData.contactPerson
        }
        if(clientData.contactMailId === undefined){
          tempdata.contactMailId =  ""
        }else{
          tempdata.contactMailId =  clientData.contactMailId
        }
        if(clientData.contactPhone === undefined){
          tempdata.contactPhone =  ""
        }else{
          tempdata.contactPhone =  clientData.contactPhone
        }
        setFormData(tempdata);
        console.log('Form data', formData);
        }
    },[clientData,isEditing]); 
    const handleChange = (event) => {
      const { name, value, type, checked } = event.target;
    
      // Use 'value' for text inputs and 'checked' for checkboxes
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    };
    function handleEnableChanged(event) {
      setpEnabled(event.target.checked);
    }
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    try {
      if(formData.clientid === "" || formData.client_country === "" || formData.client_name === ""  || formData.teamcode === "" || formData.createBy === "" || formData.createdDate === "" || formData.contactPerson === "" || formData.contactMailId === "" || formData.contactPhone === "") {
        alert("Please fill all the fields");
        return;
      }
      setIsLoading(true);
      if(penabled){
        formData.enabled = "Y";
      }else{
        formData.enabled = "N";
      }
      if (isEditing) {
        // Update an existing document
        // await setDoc(doc(db, 'clientmast', formData.clientid), formData);
        const updateResult = await updateClient(formData.clientid, formData);
        if (!updateResult.success) {
          alert('Failed to add new document. Please try again.');
          setIsLoading(false);
          return;
      }
       console.log('Document updated'); // Success message
      } else {
        // Add a new document
        const exists = await checkClientIdExists(formData.clientid);
        if (exists) {
          alert('Client ID already exists');
          setIsLoading(false); 
          return;
        }
        const addResult = await addClient(formData.clientid, formData);
        if (!addResult.success) {
            alert('Failed to add new document. Please try again.');
            setIsLoading(false);
            return;
        }
      }
      setIsLoading(false);     
      navigate('/ClientMasterList'); // Redirect back to the list after saving
    } catch (e) {
      setIsLoading(false); 
      console.error('Error adding document: ', e); // Error handling
    }
    setIsLoading(false);     
  };

  const checkClientIdExists = async (clientid) => {
    const q = query(collection(db, 'clientmast'), where('clientid', '==', clientid));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;  // Returns true if a document with the clientId exists
  };

  const handleBack = () => {
    backClicked();
  };

  return (
    <Container maxWidth="sm" sx={{ height: '600px', width: '800px' }}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiTextField-root': { m: 1 },
          '& .MuiButton-root': { m: 1 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        Client Master Entry
        <TextField
          label="Client ID"
          variant="outlined"
          name="clientid"
          value={formData.clientid}
          onChange={handleChange}
          InputProps={{
            readOnly: isEditing,  // This field will be read-only when isEditing is true
          }}          
        />
        <TextField
          label="Client Name"
          variant="outlined"
          name="client_name"
          value={formData.client_name}
          onChange={handleChange}
        />

        <TextField
          label="Country"
          variant="outlined"
          name="client_country"
          value={formData.client_country}
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={penabled}
              onChange={handleEnableChanged}
              name="penabled"
            />
          }
          label="Enabled"
        />
        <TextField
          label="Team Code"
          variant="outlined"
          name="teamcode"
          value={formData.teamcode}
          onChange={handleChange}
        />
        <TextField
          label="Created By"
          variant="outlined"
          name="createBy"
          value={formData.createBy}
          onChange={handleChange}
        />
        <TextField
          label="Created Date"
          variant="outlined"
          name="createdDate"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={formData.createdDate}
          onChange={handleChange}
        />
        <TextField
          label="Contact Person"
          variant="outlined"
          name="contactPerson"
          value={formData.contactPerson}
          onChange={handleChange}
        />
        <TextField
          label="Contact Email"
          variant="outlined"
          name="contactMailId"
          value={formData.contactMailId}
          onChange={handleChange}
        />
        <TextField
          label="Contact Phone"
          variant="outlined"
          name="contactPhone"
          value={formData.contactPhone}
          onChange={handleChange}
        />
        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        )}

         <Button variant="contained" onClick={handleBack}>Back</Button>        
        <Button variant="contained" type="submit">Submit</Button>
      </Box>
    </Container>
  );
}

export default withAuth(ClientMasterEntry)
