import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {CircularProgress,Box,TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import { TablePagination } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Menu, MenuItem, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { fetchLicData } from '../../js/LicFunc';
import { saveAs } from 'file-saver';
import useAuthListStore from '../../store/AuthListStore';

// import {fetchAllServerAuths} from '../../dbfunc/ServerAuthDbFunc'
import { FuncUpdateAuthListData } from '../../store/FuncUpdateAuthListData';
function ServerAuthListData({ onAddClicked, onMenuClicked }) {
    const [serverAuths, setServerAuths] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedServerAuth, setSelectedServerAuth] = useState(null);
    const MAuthListData = useAuthListStore((state) => state.MAuthListData);
    const [page, setPage] = useState(0);    
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredServerAuths, setFilteredServerAuths] = useState([]);
    useEffect(() => {
        if(MAuthListData.length === 0) {
            fetchServerAuths();        
        }
    }, []);
    useEffect(() => {
        setServerAuths(MAuthListData);
    }, [MAuthListData]);
    const fetchServerAuths = async () => {
        console.log('Fetching server auths');
        setIsLoading(true); 
        const response = await FuncUpdateAuthListData();
        setIsLoading(false);
        if (response) {
            console.log("update success")
            // setServerAuths(response.serverauths);
        } else {
            alert('Error fetching server auths:', response.error);
        }
    };
    useEffect(() => {
        const filteredData = serverAuths.filter(serverAuth =>
            serverAuth.serverid.toLowerCase().includes(searchTerm.toLowerCase()) ||
            serverAuth.client_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            serverAuth.location_des.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredServerAuths(filteredData);
    }, [searchTerm, serverAuths]);
    const handleMenuClick = (event, serverAuth) => {
        setAnchorEl(event.currentTarget);
        setSelectedServerAuth(serverAuth);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleDelete = async () => {
        setOpenDialog(false);
        console.log('Delete clicked', selectedServerAuth);
    };
    const handleConfirmDelete = async (feature) => {
        console.log(' handleConfirmDelete', selectedServerAuth);
        setOpenDialog(true);
    };
    
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);  // Reset to the first page
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (serverAuth) => {
        // onMenuClicked('edit', serverAuth);
        // console.log('Row clicked:', serverAuth);
        // setSelectedServerAuth(serverAuth);
    };
    const exportLicKeyData = async (feature) => {
        /*
        const blob = new Blob([data], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fname;
        a.click();
        */
        if(selectedServerAuth.serverType === undefined) {
            alert('server type not defined');
            return;
     }
     if(selectedServerAuth.enabled != "Y") {
            alert('Server is not enabled');
            return;
    }

     if(selectedServerAuth.serverType != "MAIN") {
              alert('Only MAIN server cann be exported');
              return;
       }
        try {
            const payload = {
                Serverid: selectedServerAuth.serverid,
                MechineSerial: selectedServerAuth.mac_addr,
                LicKey: selectedServerAuth.serverKey
            };
            setIsLoading(true);
            const data = await fetchLicData(payload);
            console.log('file got file data',data);
            var fname = payload.Serverid + '.lic';
            saveAs(new Blob([data]), fname);            
            setIsLoading(false);
            // Handle the data received from the API
        } catch (error) {
            // Handle errors
            console.error(error.message);
        }
    };    
    return (
        <>
        License Master List
        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        )}

    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    fullWidth
                />
            </Box>        
            <TableContainer component={Paper} sx={{ maxHeight: 650, overflowY: 'auto' }}>
                <Table sx={{ minWidth: 650 }} aria-label="server auth master table">
                    <TableHead sx={{ backgroundColor: 'purple' }}>
                        <TableRow>
                            {["Server Id","Client Name", "Location", "Enabled", "Actions"].map(header => (
                                <TableCell key={header} align="center"  sx={{ color: 'white', fontWeight: 'bold' }}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredServerAuths.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((serverAuth) => (
                            <TableRow
                                key={serverAuth.id}
                                hover
                                onClick={() => handleRowClick(serverAuth)}
                            >
                                <TableCell>{serverAuth.serverid}</TableCell>
                                <TableCell>{serverAuth.client_name}</TableCell>
                                <TableCell>{serverAuth.location_des}</TableCell>
                                <TableCell>{serverAuth.enabled ? 'Yes' : 'No'}</TableCell>
                                <TableCell>
                                    <IconButton onClick={(e) => handleMenuClick(e, serverAuth)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => onMenuClicked('edit', selectedServerAuth)}>Edit</MenuItem>
                                        <MenuItem onClick={() => onMenuClicked('apps', selectedServerAuth)}>Apps</MenuItem>
                                        <MenuItem onClick={() => onMenuClicked('subservers',selectedServerAuth)}>Sub Server</MenuItem>
                                        <MenuItem onClick={() => exportLicKeyData('exportlic')}>Export Lic</MenuItem>
                                        <MenuItem onClick={() => handleConfirmDelete('delete')}>Delete</MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageFeatures={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={serverAuths.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showLastButton = {true}
                    showFirstButton = {true}
                    sx={{ml:'50px',  mr: '20px', display: 'flex', justifyContent: 'space-between' }}                      
                />

              <Fab color="primary" aria-label="refresh" sx={{ position: 'fixed', bottom: 16, left: 16 }} onClick={() => fetchServerAuths()}>
                    <RefreshIcon />
                </Fab>                
                <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => onMenuClicked('add', null)}>
                    <AddIcon />
                </Fab>
            </TableContainer>
            <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleDelete}
                title= {selectedServerAuth ? "Delete Server " + selectedServerAuth.serverid : "Delete"}
                message="Are you sure you want to delete this server?"
            />
        </>
    );
}
export default ServerAuthListData;

