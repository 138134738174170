import React, { useEffect, useState } from 'react';
import { Container, TextField, Checkbox, FormControlLabel, Button, Box } from '@mui/material';
import withAuth from '../../withAuth';
import CircularProgress from '@mui/material/CircularProgress';
import { updateAppCode, addAppCode,checkAppCodeExists } from '../../dbfunc/AppCodeDbFunc';
import AppTypeDialog from './AppTypeDialog';
const AppCodeEntry = ({ backClicked, entryMode,pappData }) => {
  const isEditing = entryMode === "edit" ? true : false;
  const appData = pappData;
  const [isLoading, setIsLoading] = useState(false);
  const [penabled, setpEnabled] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
      appType: '',
      appcode: '',
      enabled: "Y",
      liveSyncApi: '',
    });
    function newFormData() {
      return Object.assign({}, formData);
    }
    useEffect(() => {
      console.log('App data', appData);
      console.log('isEditing', isEditing);
      var tempdata = {}
      tempdata = newFormData();
      if  (isEditing) {          
        tempdata.appcode = appData.appcode
        tempdata.appType  = appData.appType
        tempdata.enabled =  appData.enabled
        tempdata.liveSyncApi =  appData.liveSyncApi
        setFormData(tempdata);
        console.log('Form data', formData);
        if (appData.enabled === "Y") {
          setpEnabled(true);
        }  else {
          setpEnabled(false);
        }
      }
    },[appData,isEditing]); 
    const handleChange = (event) => {
      const { name, value, type, checked } = event.target;
    
      // Use 'value' for text inputs and 'checked' for checkboxes
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    };
    function handleEnableChanged(event) {
      setpEnabled(event.target.checked);
    }
    const handleSubmit = async (event) => {
      event.preventDefault();
      console.log("to save :",formData);
      try {
          if (formData.appcode === "" || formData.enabled === "") {
              alert("Please fill all the fields");
              return;
          }
          setIsLoading(true);
          formData.enabled = penabled ? "Y" : "N";
          setFormData(formData);
          if (isEditing) {
              const updateResult = await updateAppCode(formData.appcode, formData);
              if (!updateResult.success) {
                  alert('Failed to update the document. Please try again.');
                  setIsLoading(false);
                  return;
              }
              console.log('Document updated');
          } else {
              const checkResult = await checkAppCodeExists(formData.appcode);
              if (!checkResult.success) {
                  alert('Error checking document existence. Please try again.');
                  setIsLoading(false);
                  return;
              }
              if (checkResult.exists) {
                  alert('App code already exists');
                  setIsLoading(false);
                  return;
              }
              const addResult = await addAppCode(formData.appcode, formData);
              if (!addResult.success) {
                  alert('Failed to add new document. Please try again.');
                  setIsLoading(false);
                  return;
              }
          }
          setIsLoading(false);
          handleBack(); // Assuming you have a method to handle going back
      } catch (e) {
          console.error('Unexpected error:', e);
          alert('An unexpected error occurred. Please try again.');
          setIsLoading(false);
      }
      setIsLoading(false);
  };
  

  const handleBack = () => {
    // navigate('/AppCodeMasterList'); // Ensure the path is correct based on your routing setup
    backClicked("table");
  };
  const handleSelectAppType = (type) => {
    console.log('Selected app type:', type);
    setFormData({...formData, appType: type.apptype});
    setDialogOpen(false); // Close dialog after selection
  };
  return (
    <Container maxWidth="sm" sx={{ height: '600px', width: '800px' }}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiTextField-root': { m: 1 },
          '& .MuiButton-root': { m: 1 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          label="App Code"
          variant="outlined"
          name="appcode"
          value={formData.appcode}
          onChange={handleChange}
          InputProps={{
            readOnly: isEditing,  // This field will be read-only when isEditing is true
          }}          
        />
   <TextField
          label="App Type"
          variant="outlined"
          name="appType"
          value={formData.appType}
          onClick={() => setDialogOpen(true)} // Open dialog on text field click
          readOnly
        />
                <FormControlLabel
          control={
            <Checkbox
              checked={penabled}
              onChange={handleEnableChanged}
              name="penabled"
            />
          }
          label="Enabled"
        />
     <AppTypeDialog
          open={dialogOpen}
          onSelect={handleSelectAppType}
          onClose={() => setDialogOpen(false)}
        />        
        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        )}

         <Button variant="contained" onClick={handleBack}>Back</Button>        
        <Button variant="contained" type="submit">Submit</Button>
      </Box>
    </Container>
  );
}

export default withAuth(AppCodeEntry)
