import React, {  useState } from 'react';
import { Button } from '@mui/material';
import ServerAuthListData from '../components/Server/ServerAuthListData'; // You need to create this component
import ServerAuthEntry from '../components/Server/ServerAuthEntry'; // You need to create this component
import ServerAppMasterList from './ServerAppsMasterList';
// import SubServerListData from '../components/Server/SubServerListData';
import SubServerMasterList from './SubServerMasterList';
function ServerAuthMasterList() {
    const [content, setContent] = useState('table');
    const [serverAuthData, setServerAuthData] = useState(null);
    const [entryMode, setEntryMode] = useState('add');
    const handleMenuClick = (feature, serverAuth) => {
        setServerAuthData(serverAuth);
        if(feature === 'edit' || feature === 'add') {
            setEntryMode(feature === 'edit' ? 'edit' : 'add');
        }
        setContent(feature);
    };

    const handleBack = () => setContent('table');

    return (
        <>
            {content === 'table' ? (
                <ServerAuthListData onMenuClicked={handleMenuClick}  />
            ) : content === 'add' || content === 'edit' ? (
                <ServerAuthEntry backClicked={handleBack} serverAuthData={serverAuthData} entryMode={entryMode} />
            ) : content === 'apps'  ? (
                <ServerAppMasterList serverdata={serverAuthData} backClicked={handleBack} />
            ) : content === 'subservers' && serverAuthData.serverType === 'HUB' ? (
                <SubServerMasterList serverdata={serverAuthData} backClicked={handleBack} />
            ) : (
                <div style={{ padding: 20 }}>
                    <h2>{content === 'features' ? 'Features Information' : 'Disabling Information'}</h2>
                    <p>Details about {content}</p>
                    <Button variant="contained" onClick={handleBack}>Back to Table</Button>
                </div>
            )}
        </>
    );
}

export default ServerAuthMasterList;
