import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainMenu from './MainMenu';
import HomePage from './HomePage';
import LoginPage from '../src/pages/LoginPage';
import { AuthProvider } from './providers/AuthContext';
import ClientMasterList from './pages/ClientMasterList';
import AppCodeMasterList from './pages/AppCodeMasterList';
import ServerAuthMasterList from './pages/ServerAuthMasterList';
import AppTypeMasterList from './pages/AppTypeMasterList';
import { UserProvider } from './providers/UserContext';
import AuthStatusReport from './pages/AuthStatusReport';
function App() {
  return (
    <AuthProvider>
      <UserProvider>
    <BrowserRouter>
      <MainMenu />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/ClientMasterList" element={<ClientMasterList />} />
        <Route path="/ServerAuthMasterList" element={<ServerAuthMasterList />} />
        <Route path="/AppCodeMasterList" element={<AppCodeMasterList />} />
        <Route path="/AppTypeMasterList" element={<AppTypeMasterList />} />
        <Route path="/AuthStatusReport" element={<AuthStatusReport />} />
        <Route path="/login" element={<LoginPage />} />        
      </Routes>
    </BrowserRouter>
    </UserProvider>
    </AuthProvider>
  );
}

export default App;
