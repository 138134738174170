import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchAllClients } from '../../dbfunc/ClientMastDbFunc';
function ClientsLookup({ open, onClose, onSelect }) {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (open) {
            setLoading(true);
            const fetchData = async () => {
                const response = await fetchAllClients();
                if (response.success) {
                    setClients(response.clients);
                } else {
                    console.error(response.error);
                }
                setLoading(false);
            };
            fetchData();
        }
    }, [open]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6">Select a Client</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                        Close
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Search by Code or Name"
                    variant="outlined"
                    margin="dense"
                    onChange={handleSearchChange}
                />
                {loading ? <p>Loading...</p> :
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>appcode</TableCell>
                            <TableCell>Enabled</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clients.length > 0 ? clients.filter(client =>
                            Object.values(client).some(value =>
                                value.toString().toLowerCase().includes(searchQuery)
                            )).map((client) => (
                            <TableRow hover key={client.id} onClick={() => onSelect(client)}>
                                <TableCell>{client.clientid}</TableCell>
                                <TableCell>{client.client_name}</TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={2} align="center">No matching clients</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>}
            </DialogContent>
        </Dialog>
    );
}

export default ClientsLookup;
