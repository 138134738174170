import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import AuthStatusReportData from '../components/Reports/AuthStatusReportData';
import AuthExpiryReportData from '../components/Reports/AuthExpiryReportData';

function AuthStatusReport() {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    return (
        <>
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="Status Report" />
                <Tab label="Expiry Report" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                <AuthStatusReportData />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <AuthExpiryReportData />
            </TabPanel>
        </>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

export default AuthStatusReport;
