import { doc, setDoc, getDoc, getDocs, collection,deleteDoc,where,query } from 'firebase/firestore';
import { db } from '../firebase-config'; // Adjust this import as necessary

// Function to fetch all sub servers entries
export const fetchAllSubServers = async (mainserverid) => {
    try {
        const q = query(collection(db, "subservers"), where("mainserverid", "==", mainserverid));
        const querySnapshot = await getDocs(q);
       let serverauths = [];
        querySnapshot.forEach((doc) => {
            serverauths.push({ id: doc.id, ...doc.data() });
        });
        return { success: true, subservers: serverauths };
    } catch (error) {
        console.error("Error fetching sub servers:", error);
        return { success: false, error: error };
    }
};

// Function to add a new serverauth entry
export const addSubServer = async (mainserverid, subserverid,formData) => {
    console.log("mainserverid",mainserverid,"subserverid",subserverid,"formData",formData)
    try {
        const subserverkey = mainserverid + "_" + subserverid;        
        const docRef = doc(db, 'subservers', subserverkey);
        await setDoc(docRef, formData);
        return { success: true, id: docRef.id };
    } catch (error) {
        console.error("Error adding new subserver:", error);
        return { success: false, error: error };
    }
};

// Function to update an existing serverauth entry
export const updateSubServer = async (mainserverid,subserverid, formData) => {
    try {
        const serverkey = mainserverid + "_" + subserverid;
        const docRef = doc(db, 'subservers', serverkey);
        await setDoc(docRef, formData, { merge: true });
        return { success: true };
    } catch (error) {
        console.error("Failed to update serverauth:", error);
        return { success: false, error: error };
    }
};

// Function to delete a sub server entry
export const deleteSubServer = async (mainserverid,subserverid) => {
    console.log("mainserverid",mainserverid,"subserverid",subserverid)
    const serverkey = mainserverid + "_" + subserverid;
    console.log("serverkey",serverkey)
    try {
        const docRef = doc(db, 'subservers', serverkey);
        await deleteDoc(docRef);
        return { success: true };
    } catch (error) {
        console.error("Failed to delete serverauth:", error);
        return { success: false, error: error };
    }
};

// Function to check if a serverauth entry already exists
export const checkSubServerExists = async (mainserverid,subserverid) => {
    try {
        const serverkey = mainserverid + "_" + subserverid;
        const docRef = doc(db, 'subservers', serverkey);
        const docSnap = await getDoc(docRef);
        return { exists: docSnap.exists(), success: true };
    } catch (error) {
        console.error("Failed to check if sub server exists:", error);
        return { exists: false, success: false, error: error };
    }
};
