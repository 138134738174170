
import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase-config'; // Ensure this path is correct
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import { TablePagination } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Menu, MenuItem, IconButton } from '@mui/material';
import {fetchAllAppCodes} from '../../dbfunc/AppCodeDbFunc';
function AppCodeListData({ addClicked, onMenuClicked }) {
  const [appcodes, setappcodes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedappcode, setSelectedappcode] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // You can adjust this value as needed
  const [content, setContent] = useState('table'); // 'table' or 'info'

  const navigateToAppCodeEntry = () => {
      addClicked();
  };

  useEffect(() => {

    fetchAppCodes();
  }, []);
  const fetchAppCodes = async () => {
    console.log('fetchAppCodes');
    /*
    const querySnapshot = await getDocs(collection(db, "appcodes"));
    setappcodes(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    */
    const alldocs = await fetchAllAppCodes();
    if(alldocs.success){
      setappcodes(alldocs.appCodes);
    } else {
      console.log('Error fetching app codes:', alldocs.error);
    }
  };

  const handleMenuClick = (event) => {
    console.log('Item Menu clicked', event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = async (appcode) => {
    console.log('Delete clicked', appcode);
    setSelectedappcode(appcode)
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    console.log('handleConfirmDelete selectedappcode', selectedappcode);
    await deleteDoc(doc(db, "appcodes", selectedappcode));
   //  setSelectedappcode(appcodes.filter(appcode => appcode.appcode !== selectedappcode));
    setOpenDialog(false);
    const querySnapshot = await getDocs(collection(db, "appcodes"));
    setappcodes(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleMenuItemClick = (feature) => {
    console.log('Menu item clicked', feature);
    if(feature === 'edit'){
        console.log('Edit clicked', selectedappcode);
        onMenuClicked(feature,selectedappcode);
    } else if(feature === 'add'){
        console.log('Add clicked', selectedappcode);
        onMenuClicked(feature,'{}');
    } else if  (feature === 'features'){
      console.log('features clicked', selectedappcode);
      onMenuClicked(feature,selectedappcode);
  } else {
      if (typeof onMenuClicked === 'function') {
        onMenuClicked(feature,"{}");
        } else {
            console.error('onMenuClicked is not defined');
        }
    }
    handleMenuClose();
  };      
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to zero when changing the number of rows per page
  };
  const handleRowClick = (appcode) => {
    console.log('Row clicked', appcode);
    setSelectedappcode(appcode);
  }
  return (
    <>
  <TableContainer component={Paper} sx={{ maxHeight: '650px', overflowY: 'auto' }}>    
  <Table sx={{ minWidth: 650 }} aria-label="appcode master table">
  <TableHead>
      <TableRow sx={{ backgroundColor: 'purple' }}>
        {["appcode ","App Type",  "Enabled", "Url"].map((header) => (
          <TableCell key={header} align="center" sx={{ color: 'white', fontWeight: 'bold' }}>
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>        
    <TableBody>
      {appcodes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((appcode) => (
        <TableRow
          key={appcode.id}
          sx={{ '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
          hover
          style={{ cursor: 'pointer' }}
        >
          <TableCell component="th" scope="row">{appcode.appcode}</TableCell>
          <TableCell>{appcode.appType}</TableCell>
          <TableCell>{appcode.enabled}</TableCell>
          <TableCell>{appcode.liveSyncApi}</TableCell>
          <TableCell>

          <IconButton onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedappcode(appcode);
                  }}>
                    <MoreVertIcon />
                  </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleMenuItemClick('edit',appcode)}>Edit</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('delete',"")}>delete</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('features',"")}>Features</MenuItem>
              </Menu>
            </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
  <Fab color="primary" aria-label="refresh" sx={{ position: 'fixed', bottom: 16, left: 6 }} onClick={() => fetchAppCodes()}>
                    <RefreshIcon />
                </Fab>                

  <TablePagination
        rowsPerPageFeatures={[5, 10, 25, { label: 'All', value: -1 }]}
        component="div"
        count={appcodes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showLastButton = {true}
        showFirstButton = {true}
        sx={{ mr: '20px', display: 'flex', justifyContent: 'space-between' }}          
    >     
    </TablePagination>

    <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={()=> handleMenuItemClick('add',"")}>
        <AddIcon />
    </Fab>        
</TableContainer>
    <ConfirmationDialog
    open={openDialog}
    onClose={handleClose}
    onConfirm={handleConfirmDelete}
    title="Confirm Deletion"
    message="Are you sure you want to delete this appcode?"
  />  
    </>
    );
}  
export default AppCodeListData;

