import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function AppInstallType({ initialValue, onChange }) {
  const [authType, setAuthType] = useState(initialValue || 'VALIDATE');

  useEffect(() => {
    setAuthType(initialValue || 'VALIDATE');
  }, [initialValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setAuthType(value);
    onChange(value); // Propagate the selected value to the parent component
  };

  return (
    <FormControl fullWidth style={{marginTop:10}}>
      <InputLabel id="auth-type-label" >Install Type</InputLabel>
      <Select
        labelId="auth-type-label"
        id="auth-type-select"
        value={authType}
        label="Install Type"
        onChange={handleChange}
      >
        <MenuItem value="VALIDATE">VALIDATE</MenuItem>
        <MenuItem value="LOCAL">LOCAL</MenuItem>
        <MenuItem value="CLOUD">CLOUD</MenuItem>
        <MenuItem value="SHARED">SHARED</MenuItem>
      </Select>
    </FormControl>
  );
}

export default AppInstallType;
