import { setList,resetList } from './AuthListStore';
import {fetchAllServerAuths} from '../dbfunc/ServerAuthDbFunc'
// Function to set a new list
const  FuncUpdateAuthListData  = async  ()  => {
  const response = await fetchAllServerAuths();
  // console.log("response",response)
  if (response.success) {
    setList(response.serverauths);
    return true
  } else {
    console.error("could not get server data",response.error);
    return false
  }
};

export { FuncUpdateAuthListData };

