import { doc, setDoc, getDoc, getDocs, collection,where,query, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase-config'; // Adjust this import as necessary

export const fetchAllServerAppFeatures = async (serverid, appcode) => {
    console.log('fetchAllServerAppFeatures:', serverid, appcode);
    try {
        const appFeaturesRef = collection(db, 'serverauth', serverid,'serverappfeatures');
        const q = query(appFeaturesRef, where("appcode", "==", appcode));
        const querySnapshot = await getDocs(q);
        const appFeaturesData = [];
        querySnapshot.forEach((doc) => {
            appFeaturesData.push({
                id: doc.id,
                ...doc.data()
            });
        });
        return { success: true, appFeaturesData };
    } catch (error) {
        console.error("Error fetching app features:", error);
        return { success: false, error };
    }
};

// export const addServerApp = async (serverid, appcode, appData) => {


export const addServerAppFeature = async (serverid, appcode, appfeaturekey, appFeaturesData) => {
    console.log('addServerAppFeature:', serverid, appcode, appfeaturekey, appFeaturesData);
    try {
        const appFeaturesRef = collection(db, 'serverauth', serverid, 'serverappfeatures');
        const featureDocRef = doc(appFeaturesRef, appfeaturekey); // Create a document reference with the specified key
        await setDoc(featureDocRef, appFeaturesData, { merge: true });
        return { success: true, message: 'App feature added successfully' };
    } catch (error) {
        console.error("Error adding app feature:", error);
        return { success: false, error };
    }
};

export const updateServerAppFeatures = async (serverid, appfeaturekey, appFeatureData) => {
    console.log('updateServerAppFeatures:',appfeaturekey, serverid,  appFeatureData);
    try {          
        const appFeaturesRef = collection(db, 'serverauth', serverid, 'serverappfeatures');
        const featureDocRef = doc(appFeaturesRef, appfeaturekey); // Create a document reference with the specified key
        // await setDoc(featureDocRef, appFeaturesData);

        // const appFeaturesRef = collection(db, 'serverauth', serverid, 'apps', appcode, 'serverappfeatures');
        // const featureDocRef = doc(appFeaturesRef, featureKey); // Create a document reference with the specified key
        await setDoc(featureDocRef, appFeatureData, { merge: true });
        return { success: true, message: 'App feature updated successfully' };
    } catch (error) {
        console.error("Error updating app feature:", error);
        return { success: false, error };
    }
};

export const deleteServerAppFeature = async (appcode, featuresKey) => {
    try {
        const appFeatureRef = doc(collection(db, 'appcodes', appcode, 'serverappfeatures'), featuresKey);
        await deleteDoc(appFeatureRef);
        return { success: true, message: 'App feature deleted successfully' };
    } catch (error) {
        console.error("Error deleting app feature:", error);
        return { success: false, error };
    }
};

export const checkServerAppFeaturesExists = async (serverid,appcode, appfeaturekey) => {
    try {
        const colref = collection(db, 'serverauth', serverid, 'serverappfeatures');
        const appFeaturesRef = doc(colref, appfeaturekey); // Create a document reference with the specified key
        const docSnap = await getDoc(appFeaturesRef, appfeaturekey); // Create a document reference with the specified key
        return { exists: docSnap.exists(), success: true, message: docSnap.exists() ? 'App feature exists' : 'App feature does not exist' };
    } catch (error) {
        console.error("Error checking if app feature exists:", error);
        return { success: false, error, exists: false };
    }
};
