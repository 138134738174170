import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Button } from '@mui/material';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase-config';  // Adjust the path based on where your firebase-config.js is
import { useAuth } from '../providers/AuthContext';  // Adjust the path based on where your AuthContext.js is
// import { UserProvider } from '../providers/UserContext';  // Adjust the path based on where your UserContext.js is
import { UserContext } from '../providers/UserContext';  // Adjust the path based on where your UserContext.js is
import { useContext } from 'react';
function LoginPage() {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
    const { isLoggedIn,setIsLoggedIn } = useAuth();
    const { userData, setUserData } = useContext(UserContext);
    // const { userData, setUserData } = useContext(UserProvider); 
  const handleLogin = async (e) => {
    e.preventDefault();  // Prevent default form submission behavior
    try {
      const userCredential = await signInWithEmailAndPassword(auth, userId, password);
      console.log("User logged in: ", userCredential.user);
        setIsLoggedIn(true);
        
        setUserData({
          userId: userId,
          userName: '',
          isLoggedIn: true,
          userTeam: '',
          userRole: '',
          userType: ''
        });
      
      // Redirect the user or show a success message
    } catch (error) {
      console.error("Error signing in: ", error.message);
      // Handle errors here, such as showing an alert or updating the UI
    }
  };

  const handleCancel = () => {
    setUserId('');
    setPassword('');
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 500,
          height: 600,
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="userId"
            label="User ID"
            name="userId"
            autoComplete="userId"
            autoFocus
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
            <Box sx={{ height: 20 }} >
                Logged In: {isLoggedIn ? 'Yes' : 'No'}
            </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogin}
          >
            Login
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 1, mb: 2 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
