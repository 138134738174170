import React,{useEffect} from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemButton, ListItemText, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './providers/AuthContext';
function MainMenu() {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  // Function to handle navigation and close drawer
  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false); // Close the drawer
  };
  useEffect(() => {
    console.log('isLoggedIn', isLoggedIn);
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            App Name
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor={'left'} open={isOpen} onClose={toggleDrawer(false)}>
        <List>
        <ListItem disablePadding>
             <ListItemButton onClick={() => handleNavigation('/login')}>
                 <ListItemText primary="Login" />
             </ListItemButton>
          </ListItem>          

          <ListItem disablePadding>
            <ListItemButton onClick={() => handleNavigation('/')}>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
             <ListItemButton onClick={() => handleNavigation('/ClientMasterList')}>
                 <ListItemText primary="Client Master List" />
             </ListItemButton>
          </ListItem>          
          <ListItem disablePadding>
             <ListItemButton onClick={() => handleNavigation('/ServerAuthMasterList')}>
                 <ListItemText primary="Auth Server Master List" />
             </ListItemButton>
          </ListItem>     
          <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigation('/AppCodeMasterList')}>
                  <ListItemText primary="App Code Master" />
              </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
             <ListItemButton onClick={() => handleNavigation('/AppTypeMasterList')}>  
                 <ListItemText primary="App Type Master List" />
             </ListItemButton>
          </ListItem>     
          <ListItem disablePadding>
             <ListItemButton onClick={() => handleNavigation('/AuthStatusReport')}>  
                 <ListItemText primary="Auth Server Status Report" />
             </ListItemButton>
          </ListItem>     

          {/* Add more ListItemButton components for additional navigation items */}
        </List>
        {/* Featureally include a button to close the drawer */}
        <Button onClick={toggleDrawer(false)} style={{ margin: '10px' }}>Close</Button>
      </Drawer>
    </>
  );
}

export default MainMenu;
