import React, { useState,useEffect } from 'react';
import { Container, TextField, Checkbox, FormControlLabel, Button, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import withAuth from '../../withAuth'; // Adjust the import path as necessary
import { addSubServer,updateSubServer,fetchAllSubServers,checkSubServerExists } from '../../dbfunc/SubServerDbFunc';
import ServerIdLookup from '../Common/ServerIdLookup';
import { formatDate } from '../../dbfunc/genfunc';

const SubServerEntry = ({ backClicked, entryMode, mainserverid,mainmac_addr,subserverdata }) => {
  const isEditing = entryMode === "edit";
  const [isLoading, setIsLoading] = useState(false);
  const [serverLookupOpen, setServerLookupOpen] = useState(false);
  const [formData, setFormData] = useState({
    mainserverid: '',
    subserverid: '',
    subserverkey: '',
    des:'',
    enabled: "Y",
    subservertype: '',
    entrydate: formatDate(new Date()),
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  useEffect(() => {
    console.log('mainserver data',mainserverid, mainmac_addr);
    console.log('subserver data', subserverdata);
    console.log('isEditing', isEditing);
    if (isEditing) {
        setFormData(subserverdata);
    } else {
        setFormData({
            mainserverid: mainserverid,
            subserverid: '',
            des:'',
            enabled: "Y",
            subservertype: '',
            entrydate: formatDate(new Date()),
        });
    }
}, [subserverdata, isEditing]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    if (formData.subserverid === "") {
      alert("Please fill subserverid the fields");
      return;
    }
    if (formData.mainserverid === "") {
      alert("Please fill mainserverid the fields");
      return;
    }
    if (formData.subserverid === formData.mainserverid && entryMode === 'add') {
      alert("Subserverid and Mainserverid cannot be same");
      return;
    }
    try {
        if (formData.apptype === "" || formData.enabled === "") {
            alert("Please fill all the fields");
            return;
        }
        setIsLoading(true);

        if (isEditing) {
            const updateResult = await updateSubServer(mainserverid,formData.subserverid, formData);
            if (!updateResult.success) {
                alert('Failed to update the document. Please try again.');
                setIsLoading(false);
                return;
            }
            console.log('Document updated');
        } else {
            const checkResult = await checkSubServerExists(formData.mainserverid,formData.subserverid);
            if (!checkResult.success) {
                alert('Error checking document existence. Please try again.');
                setIsLoading(false);
                return;
            }
            if (checkResult.exists) {
                alert('App code already exists');
                setIsLoading(false);
                return;
            }
            const subserverkey = mainserverid + "_" + formData.subserverid;
            formData.subserverkey = subserverkey;
            const addResult = await addSubServer(mainserverid,formData.subserverid, formData);
            if (!addResult.success) {
                alert('Failed to add new document. Please try again.');
                setIsLoading(false);
                return;
            }
        }
        setIsLoading(false);
        handleBack(); // Assuming you have a method to handle going back
    } catch (e) {
        console.error('Unexpected error:', e);
        alert('An unexpected error occurred. Please try again.');
        setIsLoading(false);
    }
    setIsLoading(false);
};

  const handleBack = () => {
    backClicked("back",null);
  };
  const handleServerSelected = (c) => {
    if(entryMode === 'edit'){
      alert('Cannot change subserverid in edit mode');
      return;
    }
    console.log('Selected client:', c);
    if (c.serverid === formData.mainserverid) {
      alert('Subserverid and Mainserverid cannot be same');
      return;
    }
    if(c.enabled === 'N'){
      alert('Server is disabled');
      return;
    }
    if(c.serverType === undefined){
      alert('Server type not defined');
      return;
    }
    if(c.serverType !== 'SUB'){
      alert('Server type is not SUB');
      return;
    }
    if(c.mac_addr !=  mainmac_addr){
      alert('Server MAC address is different');
      return;
    }
    setFormData({...formData,subserverid: c.serverid});
    setServerLookupOpen(false);
  };  

  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiTextField-root': { m: 1 },
          '& .MuiButton-root': { m: 1 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          label="Main server ID"
          variant="outlined"
          name="mainserverid"
          value={formData.mainserverid}
          onChange={handleChange}
          readOnly          
        />
        <TextField
            label="Sub server ID"
            variant="outlined"
            name="subserverid"
            value={formData.subserverid}
            onChange={handleChange}
            onClick={() => setServerLookupOpen(true)} // Open dialog on text field click
            readOnly
            />
        <TextField
          label="Description"
          variant="outlined"
          name="des"
          value={formData.des}
          onChange={handleChange}
        />
            <FormControlLabel
            control={
                <Checkbox
                checked={formData.enabled === 'Y'}
                onChange={handleChange}
                name="enabled"
                />
            }
            label="Enabled"
            />
        <TextField
          label="Sub server type"
          variant="outlined"
          name="subservertype"
          value={formData.subservertype}
          onChange={handleChange}
        />
        <TextField
            label="Entry date"
            variant="outlined"
            name="entrydate"
            value={formData.entrydate}
            onChange={handleChange}
        />
            <ServerIdLookup open={serverLookupOpen} onClose={() => setServerLookupOpen(false)} onSelect={(handleServerSelected)} />

        {isLoading && <CircularProgress />}
        <Button variant="contained" onClick={handleBack}>Back</Button>
        <Button variant="contained" type="submit">Submit</Button>
      </Box>
    </Container>
  );
}

export default withAuth(SubServerEntry);
