import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import AppTypeListData from '../components/Apps/AppTypeListData';
import AppTypeEntry from '../components/Apps/AppTypeEntry';

function AppTypeMasterList() {
  const [content, setContent] = useState('table'); // 'table', 'add', 'edit', 'info'
  const [appTypeData, setAppTypeData] = useState(null);
  const [entryMode, setEntryMode] = useState('add');

  const handleMenuClick = (feature, apptype) => {
    console.log('Menu clicked in main window', feature, apptype);
    setContent(feature);
    setAppTypeData(apptype);
    setEntryMode(feature === 'edit' ? 'edit' : 'add');
  };

  return (
    <>
      {content === 'table' ? (
        <AppTypeListData onMenuClicked={handleMenuClick} />
      ) : ['add', 'edit'].includes(content) ? (
        <AppTypeEntry backClicked={() => setContent('table')} appTypeData={appTypeData} entryMode={entryMode} />
      ) : (
        <div style={{ padding: 20 }}>
          <h2>{content === 'features' ? 'Features Information' : 'Disabling Information'}</h2>
          <p>Details about {content}</p>
          <Button variant="contained" onClick={() => setContent('table')}>Back to Table</Button>
        </div>
      )}
    </>
  );
}

export default AppTypeMasterList;
