import { doc, setDoc, getDoc, getDocs, collection,where } from 'firebase/firestore';
import { db } from '../firebase-config'; // Adjust this import as necessary
import {fetchAllServerAuths} from './ServerAuthDbFunc';
// Function to fetch all serverauth entries
export const fetchAllAuthServerStatus = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'AuthStatus'));
        let serverauths = [];
        querySnapshot.forEach((doc) => {
            serverauths.push({ id: doc.id, client_id: '', client_name: '', sales_team: '', grace_period: '', ...doc.data() });
        });
        console.log("Fetched server status:", serverauths);
        // Check if serverauths is empty
        if (serverauths.length > 0) {
            const allserverdata = await fetchAllServerAuths();
            if (allserverdata.success) {
                let allserverlist = allserverdata.serverauths;
                for (let i = 0; i < serverauths.length; i++) {
                    const match = allserverlist.find(newItem => newItem.serverid === serverauths[i].Serverid);
                    if (match) {
                        serverauths[i].client_id = match.clientid;
                        serverauths[i].client_name = match.client_name;
                        serverauths[i].sales_team = match.locid;
                        serverauths[i].grace_period = match.grace_period;
                    }
                }
            }
        }

        return { success: true, serverlist: serverauths };
    } catch (error) {
        console.error("Error fetching serverauths:", error);
        return { success: false, error: error };
    }
};

export const fetchAuthAppsByValidDate = async (givenDate) => {
    try {
        const allserverdata = await fetchAllServerAuths();
        let validDocuments = [];
        let allapps = [];
        console.log("allserverdata", allserverdata);
        for (let i = 0; i < allserverdata.serverauths.length; i++) {
            const serverid = allserverdata.serverauths[i].serverid;
            const serverAuthRef = doc(db, 'serverauth', serverid);
            const appsRef = collection(serverAuthRef, 'apps');
            const querySnapshot = await getDocs(appsRef);
            let apps = [];
            querySnapshot.forEach((doc) => {
                apps.push({ id: doc.id, ...doc.data() });
                allapps.push({ id: doc.id, ...doc.data() });
                console.log("apps doc.data()", doc.data());
            });
            allserverdata.serverauths[i].apps = apps;
            if (i > 10) {
                console.log("breaking loop");
                break;
            }
        }
        validDocuments = allserverdata
        console.log("validDocuments", validDocuments);
        console.log("allserverdata", allserverdata);
        return { success: true, validDocuments: validDocuments,allserverdata:allserverdata,allapps:allapps };
    } catch (error) {
        console.error("Error fetching documents:", error);
        return { success: false, error: error };
    }
};
