
// dateUtils.js
export function formatDate(date) {
    const d = date || new Date(); // Use current date if none is provided
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = d.getFullYear();
    // return `${day}-${month}-${year}`;
    // make it to yyyy-mm-dd
    return `${year}-${month}-${day}`;
}
