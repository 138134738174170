import { doc, setDoc,deleteDoc, getDoc, getDocs, collection, query, where  } from 'firebase/firestore';
import { db } from '../firebase-config'; // Adjust this import as necessary

// Function to fetch all app types
export const fetchAllAppTypes = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'apptypes'));
        let appTypes = [];
        querySnapshot.forEach((doc) => {
            appTypes.push({ id: doc.id, ...doc.data() });
        });
        console.log("Fetched apptypes:", appTypes);
        return { success: true, appTypes };
    } catch (error) {
        console.error("Error fetching apptypes:", error);
        return { success: false, error: error };
    }
};

// Function to add a new app type with a custom ID
export const addAppType = async (apptypeId, formData) => {
    try {
        const docRef = doc(db, 'apptypes', apptypeId);
        await setDoc(docRef, formData);
        return { success: true, id: docRef.id };
    } catch (error) {
        console.error("Error adding new apptype:", error);
        return { success: false, error: error };
    }
};

// Function to update an existing app type
export const updateAppType = async (apptypeId, formData) => {
    try {
        const docRef = doc(db, 'apptypes', apptypeId);
        await setDoc(docRef, formData, { merge: true });
        return { success: true };
    } catch (error) {
        console.error("Failed to update apptype:", error);
        return { success: false, error: error };
    }
};

// Function to check if an app type already exists
export const checkAppTypeExists = async (apptypeId) => {
    console.log("Checking if apptype exists:", apptypeId);
    try {
        const docRef = doc(db, 'apptypes', apptypeId);
        const docSnap = await getDoc(docRef);
        return { exists: docSnap.exists(), success: true };
    } catch (error) {
        console.error("Failed to check if apptype exists:", error);
        return { exists: false, success: false, error: error };
    }
};

export const deleteAppType = async (apptypeId) => {
    try {
      const docRef = doc(db, 'apptypes', apptypeId);
      await deleteDoc(docRef);
      return { success: true };
    } catch (error) {
      console.error("Failed to delete apptype:", error);
      return { success: false, error: error };
    }
  };
  
  export const isAppTypeUsed = async (appType) => {
    try {
      const q = query(collection(db, "appcodes"), where("appType", "==", appType));
      const querySnapshot = await getDocs(q);
  
      // Check if any documents exist
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error checking appType usage:", error);
      return false;
    }
  };
