// firebase functions for appcodes
import { doc, setDoc, getDoc,getDocs,collection } from 'firebase/firestore';
import { db } from '../firebase-config'; // Adjust this import as necessary

// Function to add a new document with a custom ID, with error handling
// get list of appcodes

export const fetchAllAppCodes = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'appcodes'));
        let appcodes = [];
        querySnapshot.forEach((doc) => {
            // Push each doc into an array with the doc ID included
            appcodes.push({ id: doc.id, ...doc.data() });
        });
        console.log("Fetched appcodes:", appcodes);
        return { success: true, appCodes: appcodes }; // Return an object with a success status and the array of appcodes
    } catch (error) {
        console.error("Error fetching appcodes:", error);
        return { success: false, error: error }; // Return error information for further handling
    }
};
export const addAppCode = async (appcode, formData) => {
    try {
        const docRef = doc(db, 'appcodes', appcode);
        await setDoc(docRef, formData);
        return { success: true, id: docRef.id }; // Returning success status and document ID
    } catch (error) {
        console.error("Error adding new appcode:", error);
        return { success: false, error: error }; // Return error information for further handling
    }
};

// Function to update an existing document with error handling
export const updateAppCode = async (appcode, formData) => {
    try {
        const docRef = doc(db, 'appcodes', appcode);
        await setDoc(docRef, formData);
        return { success: true }; // Featureally return a success status
    } catch (error) {
        console.error("Failed to update appcode:", error);
        return { success: false, error: error }; // Return error information for further handling
    }
};

// Function to check if an app code already exists, with error handling
export const checkAppCodeExists = async (appcode) => {
    console.log("Checking if appcode exists:", appcode);
    try {
        const docRef = doc(db, 'appcodes', appcode);
        const docSnap = await getDoc(docRef);
        return { exists: docSnap.exists(), success: true }; // Returning existence status and success
    } catch (error) {
        console.error("Failed to check if appcode exists:", error);
        return { exists: false, success: false, error: error }; // Return error information for further handling
    }
};
