
import React, { useEffect, useState } from 'react';
import {  Button } from '@mui/material';
import AppCodeListData from '../components/Apps/AppCodeListData';
import AppCodeEntry from '../components/Apps/AppCodeEntry';
import AppFeaturesMasterList from '../components/Apps/AppFeaturesMasterList';
function AppCodeMasterList() {
  const [content, setContent] = useState('table'); // 'table' or 'info'
  const [appcodeData, setAppCodeData] = useState(null);
  const [entryMode, setEntryMode] = useState('add');
  const addClicked = () => {
     setContent('entry');
  };
  useEffect(() => {

}, []);



  const handleMenuClick = (feature,appcode)=> {
    // setAnchorEl(event.currentTarget);
    console.log('Menu clicked in mainwindow', feature,appcode);
    setContent(feature);
    setAppCodeData(appcode);
    if(feature === 'edit'){
        setEntryMode('edit');
    } else {
        setEntryMode('add');
    }
  };
  const featuresBackClicked = () => {
    setContent('table');
  }
  return (
    <>
      {content === 'table' ? (    
        <AppCodeListData addClicked={addClicked} onMenuClicked={handleMenuClick} />
        ) : content === 'add' || content === 'edit'  ? (
          <AppCodeEntry backClicked={handleMenuClick} pappData = {appcodeData} entryMode = {entryMode} />  // Featureally pass props if needed
        ) : content === 'features'   ? (
          <AppFeaturesMasterList backClicked={featuresBackClicked}  appdata={appcodeData}/>
        ) : (     
          <div style={{ padding: 20 }}>
          <h2>{content === 'features' ? 'Features Information' : 'Disabling Information'}</h2>
          <p>Details about {content}</p>
          <Button variant="contained" onClick={() => setContent('table')}>Back to Table</Button>
        </div>
      )}      
    </>
  );
}

export default AppCodeMasterList;
