import create from 'zustand';

// Define the store
const useAuthListStore = create((set) => ({
  MAuthListData: [],
  addItem: (item) => set((state) => ({ MAuthListData: [...state.MAuthListData, item] })),
  removeItem: (id) => set((state) => ({
    MAuthListData: state.MAuthListData.filter((item) => item.id !== id)
  })),
  updateItem: (updatedItem) => set((state) => ({
    MAuthListData: state.MAuthListData.map((item) =>
      item.id === updatedItem.id ? updatedItem : item
    )
  })),
  setList: (newList) => set({ MAuthListData: newList }),
}));

export const setList = (newList) => useAuthListStore.getState().setList(newList);
export const resetList = () => useAuthListStore.getState().setList([]);

export default useAuthListStore;
