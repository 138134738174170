import axios from 'axios';

export const fetchLicData = async (payload) => {
    const baseurl = "https://us-central1-applicense-3b654.cloudfunctions.net";
    const api = baseurl + "/DownLoadLic";
    const paraData = { 
        Serverid: payload.Serverid,
        MechineSerial: payload.MechineSerial,
        LicKey: payload.LicKey
    };
    try {
        const response = await axios.post(api, paraData, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
                // Add additional headers if needed
            }
        });
        console.log("FetchLicData server response  success\n");
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error("FetchLicData error in downloading file");
    }
};


export const ProcActKey = async (payload) => {
    const baseurl = "https://us-central1-applicense-3b654.cloudfunctions.net";
    const api = baseurl + "/GetLicKey";
    const paraData = {
        Serverid: payload.Serverid,
        MechineSerial: payload.MechineSerial
    };

    try {
        const response = await axios.post(api, paraData, {
            headers: {}
        });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};
