import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import SubServerEntry from '../components/Server/SubServerEntry';
import SubServerListData from '../components/Server/SubServerListData';

const  SubServerMasterList = ({ serverdata, backClicked }) => {
  const [content, setContent] = useState('table');
  const [serverData, setServerData] = useState(serverdata);
  const [subServerData, setSubServerData] = useState(null);
  const [entryMode, setEntryMode] = useState('table');

  const addNewClicked = () => {
    console.log('Add clicked ',serverData);
    setEntryMode('add');
    setContent('add');
  };
  const editClicked = (server) => {
    console.log('Edit clicked ',server);
    setSubServerData(server);
    setEntryMode('edit');
    setContent('edit');
  };
  useEffect(() => {
    console.log('Server data:', serverdata);
    setServerData(serverdata);
  }, [serverdata]);
  const handleMenuClick = (feature, server) => {
    console.log('Menu clicked in main window', feature, server);
    if (feature === 'back') {
      setContent('table');
      return;
    }
    setContent(feature);
    setSubServerData(server);
    if (feature === 'edit') {
      if (server === null) {
        alert('Please select a server first');
        return;
      }
      setEntryMode('edit');
      setContent('edit');
    } else {
      if (feature === 'table') {
        setEntryMode('');
        setContent('table');
        return;
      }
      if (serverData.serverid === undefined) {
        alert('Please select a server first');
        return;
      }
      if (serverData.serverid === '') {
        alert('Please select a server first');
        return;
      }
      if (feature === 'add') {
        setEntryMode('add');
        setContent('add');
      }
    }
  };

  function handleBack() {
    backClicked();
  }

  return (
    <>
      {content === 'table' ? (
        <div>
          <Button variant="contained" onClick={() => handleBack()}>Back to Server List</Button>
          <SubServerListData  addNew= {addNewClicked} backClicked={handleBack} serverdata={serverData} editClicked={editClicked} />
        </div>
      ) : content === 'add' || content === 'edit' ? (
        <SubServerEntry backClicked={handleMenuClick} entryMode={entryMode}  mainserverid={serverData.serverid} mainmac_addr = {serverData.mac_addr}  subserverdata={subServerData}/>
      ) : (
        <div style={{ padding: 20 }}>
          <p>Details about {content}</p>
          <Button variant="contained" onClick={() => setContent('table')}>Back to Table</Button>
        </div>
      )}
    </>
  );
}

export default SubServerMasterList;
