import { doc, setDoc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase-config'; // Adjust this import as necessary

// Function to fetch all serverauth entries
export const fetchAllServerAuths = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'serverauth'));
        let serverauths = [];
        querySnapshot.forEach((doc) => {
            serverauths.push({ id: doc.id, ...doc.data() });
        });
        return { success: true, serverauths };
    } catch (error) {
        console.error("Error fetching serverauths:", error);
        return { success: false, error: error };
    }
};

// Function to add a new serverauth entry
export const addServerAuth = async (serverauthId, formData) => {
    try {
        const docRef = doc(db, 'serverauth', serverauthId);
        await setDoc(docRef, formData);
        return { success: true, id: docRef.id };
    } catch (error) {
        console.error("Error adding new serverauth:", error);
        return { success: false, error: error };
    }
};

// Function to update an existing serverauth entry
export const updateServerAuth = async (serverauthId, formData) => {
    try {
        const docRef = doc(db, 'serverauth', serverauthId);
        await setDoc(docRef, formData, { merge: true });
        return { success: true };
    } catch (error) {
        console.error("Failed to update serverauth:", error);
        return { success: false, error: error };
    }
};

// Function to check if a serverauth entry already exists
export const checkServerAuthExists = async (serverauthId) => {
    try {
        const docRef = doc(db, 'serverauth', serverauthId);
        const docSnap = await getDoc(docRef);
        return { exists: docSnap.exists(), success: true };
    } catch (error) {
        console.error("Failed to check if serverauth exists:", error);
        return { exists: false, success: false, error: error };
    }
};
