import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import {db } from '../../firebase-config';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {CircularProgress,Box,TextField, Table, TableBody, TableCell, TableContainer,Collapse, TableHead, TableRow, Paper, Button } from '@mui/material';
import { TablePagination } from '@mui/material';
import {formatDate} from '../../dbfunc/genfunc';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// import Fab from '@mui/material/Fab';
// import AddIcon from '@mui/icons-material/Add';
import { Menu, MenuItem, IconButton } from '@mui/material';
// import ReplyIcon from '@mui/icons-material/Reply';
import {fetchAuthAppsByValidDate} from '../../dbfunc/ReportsDbFunc';
function AuthExpiryReportData({ onAddClicked, onMenuClicked }) {
    const [serverAuths, setServerAuths] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedServerAuth, setSelectedServerAuth] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredServerAuths, setFilteredServerAuths] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);

    /*
    useEffect(() => {
        fetchServerAuths();
    }, []);
    */
    const fetchServerAuths = async () => {
        alert("not completed yet!")
        return
        console.log('Fetching server auths');
        setIsLoading(true); 
        var today = formatDate(new Date());
        const serverAuths = await fetchAuthAppsByValidDate(today);
        if(serverAuths.success){
            console.log('Server auths list:', serverAuths.validDocuments);
            setServerAuths(serverAuths.allserverdata);
            setFilteredServerAuths(serverAuths.serverauths);
        } else {
            console.error('Error fetching server auths:', serverAuths.error);
        }        
        setIsLoading(false);
    };
    const handleExpandClick = (serverAuth) => {
        if (expandedRows.includes(serverAuth.Serverid)) {
            setExpandedRows(expandedRows.filter(serverAuth => serverAuth.Serverid !== serverAuth.Serverid));
        } else {
            setExpandedRows([...expandedRows, serverAuth]);
        }
        console.log('Expanded rows:', expandedRows);
    };
    const isRowExpanded = (serverAuth) => {
        return expandedRows.includes(serverAuth.Serverid);
    };

    /*
    useEffect(() => {
        const filteredData = serverAuths.filter(serverAuth =>
            serverAuth.Serverid.toLowerCase().includes(searchTerm.toLowerCase()) ||
            serverAuth.client_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            serverAuth.sales_team.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredServerAuths(filteredData);
    }, [searchTerm, serverAuths]);
    */
    const handleMenuClick = (event, serverAuth) => {
        setAnchorEl(event.currentTarget);
        setSelectedServerAuth(serverAuth);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);  // Reset to the first page
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (serverAuth) => {
        // onMenuClicked('edit', serverAuth);
        // console.log('Row clicked:', serverAuth);
        // setSelectedServerAuth(serverAuth);
    };
    return (
        <>
        Expired Apps Report
        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        )}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    fullWidth
                />
                <Button variant="contained" onClick={fetchServerAuths} sx={{marginLeft:5}}>Fetch Data</Button>
            </Box>        
            <TableContainer component={Paper} sx={{ maxHeight: 650, overflowY: 'auto' }}>
                <Table sx={{ minWidth: 650 }} aria-label="server connection status">
                    <TableHead sx={{ backgroundColor: 'purple' }}>
                        <TableRow>
                            {["Apps", "Server Id","Client Name", "Message of Connection", "Connect Time", "Connect Status","Server ip","Date"].map(header => (
                                <TableCell key={header} align="center"  sx={{ color: 'white', fontWeight: 'bold' }}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredServerAuths.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((serverAuth) => (
                            <TableRow
                                key={serverAuth.id}
                                hover
                                onClick={() => handleRowClick(serverAuth)}
                            >
                                <TableCell>
                                    <IconButton size="small" onClick={() => handleExpandClick(serverAuth)}>
                                        {isRowExpanded(serverAuth.Serverid) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    </IconButton>
                                </TableCell>                                
                                <TableCell>{serverAuth.Serverid}</TableCell>
                                <TableCell>{serverAuth.client_name}</TableCell>
                                <TableCell>{serverAuth.Connect_message}</TableCell>
                                <TableCell>{serverAuth.Time_stamp}</TableCell>
                                <TableCell>{serverAuth.Auth_success}</TableCell>
                                <TableCell>{serverAuth.Server_ip}</TableCell>
                                <TableCell>{serverAuth.Last_connect_date}</TableCell>
                                <TableCell>
                                    <IconButton onClick={(e) => handleMenuClick(e, serverAuth)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => onMenuClicked('export', selectedServerAuth)}>Export</MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageFeatures={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={serverAuths.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showLastButton = {true}
                    showFirstButton = {true}
                    sx={{ml:'50px',  mr: '20px', display: 'flex', justifyContent: 'space-between' }}                      
                />

            </TableContainer>
        </>
    );
}

export default AuthExpiryReportData;
