import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import ClientMastListData from '../components/Clients/ClientMastListData';
import ClientMasterEntry from '../components/Clients/ClientMasterEntry';
function ClientMasterList() {
  const [content, setContent] = useState('table'); // 'table', 'add', 'edit', or 'info'
  const [clientData, setClientData] = useState(null);
  const [entryMode, setEntryMode] = useState('add');

  const addClicked = () => {
    setContent('add');
  };

  useEffect(() => {
    // Add any initialization code here if needed
  }, []);

  const handleMenuClick = (feature, client) => {
    console.log('Menu clicked in main window', feature, client);
    setContent(feature);
    setClientData(client);
    if (feature === 'edit') {
      setEntryMode('edit');
    } else {
      setEntryMode('add');
    }
  };

  return (
    <>
      {content === 'table' ? (
        <ClientMastListData onMenuClicked={handleMenuClick} addClicked={addClicked} />
      ) : content === 'add' || content === 'edit' ? (
        <ClientMasterEntry backClicked={() => setContent('table')} clientdata={clientData}  entryMode={entryMode} />
      ) : (
        <div style={{ padding: 20 }}>
          <h2>{content === 'features' ? 'Features Information' : 'Disabling Information'}</h2>
          <p>Details about {content}</p>
          <Button variant="contained" onClick={() => setContent('table')}>Back to Table</Button>
        </div>
      )}
    </>
  );
}

export default ClientMasterList;
