import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function FeatureVerTypeSelect({ initialValue, onChange }) {
  const [authType, setAuthType] = useState(initialValue || 'COM');

  useEffect(() => {
    setAuthType(initialValue || 'COM');
  }, [initialValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setAuthType(value);
    onChange(value); // Propagate the selected value to the parent component
  };

  return (
    <FormControl fullWidth style={{marginTop:10}}>
      <InputLabel id="auth-type-label" >Version Type</InputLabel>
      <Select
        labelId="auth-type-label"
        id="auth-type-select"
        value={authType}
        label="Version Type"
        onChange={handleChange}
      >
        <MenuItem value="COM">COMMON</MenuItem>
        <MenuItem value="ENT">ENTRY LEVEL</MenuItem>
        <MenuItem value="STD">STANDARD</MenuItem>
        <MenuItem value="ADV">ADVANCE</MenuItem>
        <MenuItem value="ENP">ENTERPRISE</MenuItem>
        <MenuItem value="CUS">CUSTOM</MenuItem>
      </Select>
    </FormControl>
  );
}

export default FeatureVerTypeSelect;
