import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import ServerAppEntry from '../components/Server/ServerAppEntry';
import ServerAppListData from '../components/Server/ServerAppListData';
import ServerAppFeaturesMastList from '../components/Server/ServerAppFeaturesMastList';
function ServerAppMasterList({serverdata,backClicked}) {
  const [content, setContent] = useState('table');  // 'table' or 'info'
  const [appData, setAppData] = useState(null);
  const [serverData, setServerData] = useState(serverdata);
  const [entryMode, setEntryMode] = useState('add');

  const addClicked = () => {
    setContent('entry');
  };
  useEffect(() => {
    console.log('Server data:', serverdata);
    setServerData(serverdata);
  }, [serverdata]);
  const handleMenuClick = (feature, app) => {
    console.log('Menu clicked in main window', feature, app);
    setContent(feature);
    setAppData(app);
    if(feature === 'edit'){
      setEntryMode('edit');
    } else {
      if(feature === 'table'){
        setContent('table');
        return;
      }
      if(serverData.serverid === undefined){
            alert('Please select a server first');
            return;
      }
      if(serverData.serverid == ''){
          alert('Please select a server first');
          return;
      }
      if(feature === 'features'){
        setContent('features');
        return;
      }
      setEntryMode('add');
    }
  };
  function handleBack(){
    backClicked();
  }
  return (
    <>
      {content === 'table' ? (
        <div>
            <Button variant="contained" onClick={() => handleBack()}>  Back to Server List </Button>
            <ServerAppListData addClicked={addClicked} onMenuClicked={handleMenuClick}  serverdata={serverData}/>
        </div>
      ) : content === 'add' || content === 'edit' ? (
        <ServerAppEntry backClicked={handleMenuClick} appData={appData} entryMode={entryMode} serverdata = {serverData} serverid={serverData.serverid} clientid={serverData.clientid} />
      )  : content === 'features' ? (
        <ServerAppFeaturesMastList serverid={serverData.serverid} appdata={appData} backClicked={handleMenuClick} />
    ) 
    : (
        <div style={{ padding: 20 }}>
          <p>Details about {content}</p>
          <Button variant="contained" onClick={() => setContent('table')}>Back to Table</Button>
        </div>
      )}
    </>
  );
}

export default ServerAppMasterList;
