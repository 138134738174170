import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Menu, MenuItem, TablePagination } from '@mui/material';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import {CircularProgress, Box} from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ReplyIcon from '@mui/icons-material/Reply';
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import {fetchAllSubServers,deleteSubServer} from '../../dbfunc/SubServerDbFunc';

const  SubServerListData = ({serverdata,addNew, backClicked,editClicked})  =>{
    const [subServerList, setSubServerList] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [subServerData, setSubServerData] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        console.log(' Server data:', serverdata);
        console.log('type of addClicked:', typeof addClicked)
        console.log('type of addNew:', typeof addNew)
        fetchSubServers();
    }, [serverdata]);

    const fetchSubServers = async () => {
        if(serverdata === undefined){
            alert('server data empty');
            return;
        }
        console.log(' Server main server data:', serverdata);
        setIsLoading(true);
        const alldocs = await fetchAllSubServers(serverdata.serverid);
        setIsLoading(false);
        if(alldocs.success){
            setSubServerList(alldocs.subservers);
            console.log('subservers:', alldocs.subservers);
        } else {
            console.log('Error fetching sub servers:', alldocs.error);
        }
    };
    const handleAddClicked = () => {
        console.log('Add clicked');
        console.log('type of addNew',typeof addNew)
        addNew();
       // addClicked();
    }

    const handleConfirmDelete = async () => {
        console.log(' handleConfirmDelete', subServerData);
        setSubServerData(subServerData);
        setOpenDialog(true);
    }
    const deleteRow = async () => {
        console.log('Delete row:', subServerData);
        setOpenDialog(false);
        setIsLoading(true);
        const result = await deleteSubServer(subServerData.mainserverid,subServerData.subserverid);
        if(!result.success){
            alert('Error deleting sub server ', result.error);
        } else {
            alert('sub server  deleted ' + subServerData.subserverid);
            fetchSubServers(); 
        }
        setIsLoading(false);
    }
    const handleMenuClick = (feature,subserver) => {
        if(feature === 'add') {
            addNew();
            return
        }
        console.log('Menu clicked:', feature);
        console.log('Menu clicked:', feature, subserver);
        if(feature === 'edit'){
            editClicked(subserver);
        } else if(feature === 'delete'){
            handleConfirmDelete();
        }
    }
    const handleBackClicked = () => {
        backClicked();
    }
    return (
        <>
        {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Box>
        )}
        <TableContainer component={Paper}>
        <Table aria-label="sub server  list">
        <TableHead>
          <TableRow>
            {["sub server id", "Enabled", "Type", "entrydate"].map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {subServerList.map((server) => (
            <TableRow key={server.id}
              style={{ cursor: 'pointer' }} 
            >               
              <TableCell>{server.subserverid}</TableCell>
              <TableCell>{server.enabled}</TableCell>
              <TableCell>{server.subservertype}</TableCell>
              <TableCell>{server.entrydate}</TableCell>
              {/* More fields and possibly action buttons */}
              <TableCell>
                  <IconButton onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSubServerData(server);
                  }}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem onClick={() => handleMenuClick('edit',server)}>Edit</MenuItem>
                    <MenuItem onClick={() => handleMenuClick('delete',server)}>Delete</MenuItem>
                  </Menu>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

        </TableContainer>

        <Fab color="primary" aria-label="refresh" sx={{ position: 'fixed', bottom: 16, left: 16 }} onClick={() => fetchSubServers()}>
        <RefreshIcon />
      </Fab>      

      <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => handleAddClicked()}>
        <AddIcon />
      </Fab>
            <ConfirmationDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                onConfirm={deleteRow}
                title="Confirm Deletion"
                message={`Are you sure you want to delete this sub SErver: ${subServerData?.subserverid}?`}
            />
        </>
    );
}

export default SubServerListData;




