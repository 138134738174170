import { doc, setDoc, getDoc, getDocs, collection,where,query,deleteDoc } from 'firebase/firestore';

import { db } from '../firebase-config'; // Adjust this import as necessary

// Function to fetch all apps for a specific serverid
export const fetchAllSalesTeams = async () => {
    console.log("Fetching all salesteam...");
    try {
        const querySnapshot = await getDocs(collection(db, 'salesteam'));
        let teams = [];
        querySnapshot.forEach((doc) => {
            teams.push({ id: doc.id, ...doc.data() });
        });
        console.log("Fetched salesteam:", teams);
        return { success: true, teams };
    } catch (error) {
        console.error("Error fetching salesteam:", error);
        return { success: false, error: error };
    }
};
