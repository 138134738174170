import React, { useState,useEffect } from 'react';
import { Container, TextField, Checkbox, FormControlLabel, Button, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import withAuth from '../../withAuth'; // Adjust the import path as necessary
import { addAppType,checkAppTypeExists,updateAppType } from '../../dbfunc/AppTypesDbFunc';

const AppTypeEntry = ({ backClicked, entryMode, appTypeData }) => {
  const isEditing = entryMode === "edit";
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    apptype: '',
    des: '',
    enabled: true,
    logurl: '',
    other_data: ''
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  useEffect(() => {
    console.log('App data', appTypeData);
    console.log('isEditing', isEditing);
    if (isEditing) {
        setFormData(appTypeData);
    }
}, [appTypeData, isEditing]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    try {
        if (formData.apptype === "" || formData.enabled === "") {
            alert("Please fill all the fields");
            return;
        }
        setIsLoading(true);

        if (isEditing) {
            const updateResult = await updateAppType(formData.apptype, formData);
            if (!updateResult.success) {
                alert('Failed to update the document. Please try again.');
                setIsLoading(false);
                return;
            }
            console.log('Document updated');
        } else {
            const checkResult = await checkAppTypeExists(formData.apptype);
            if (!checkResult.success) {
                alert('Error checking document existence. Please try again.');
                setIsLoading(false);
                return;
            }
            if (checkResult.exists) {
                alert('App code already exists');
                setIsLoading(false);
                return;
            }
            const addResult = await addAppType(formData.apptype, formData);
            if (!addResult.success) {
                alert('Failed to add new document. Please try again.');
                setIsLoading(false);
                return;
            }
        }
        setIsLoading(false);
        handleBack(); // Assuming you have a method to handle going back
    } catch (e) {
        console.error('Unexpected error:', e);
        alert('An unexpected error occurred. Please try again.');
        setIsLoading(false);
    }
    setIsLoading(false);
};

  const handleBack = () => {
    backClicked("table");
  };

  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiTextField-root': { m: 1 },
          '& .MuiButton-root': { m: 1 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          label="App Type"
          variant="outlined"
          name="apptype"
          value={formData.apptype}
          onChange={handleChange}
        />
        <TextField
          label="Description"
          variant="outlined"
          name="des"
          value={formData.des}
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.enabled}
              onChange={handleChange}
              name="enabled"
            />
          }
          label="Enabled"
        />
        <TextField
          label="Log URL"
          variant="outlined"
          name="logurl"
          value={formData.logurl}
          onChange={handleChange}
        />
        <TextField
          label="Other Data"
          variant="outlined"
          name="other_data"
          value={formData.other_data}
          onChange={handleChange}
        />
        {isLoading && <CircularProgress />}
        <Button variant="contained" onClick={handleBack}>Back</Button>
        <Button variant="contained" type="submit">Submit</Button>
      </Box>
    </Container>
  );
}

export default withAuth(AppTypeEntry);
