import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { blue ,yellow} from '@mui/material/colors';

function UserCountTypeSelection({ initialValue, onChange }) {
  const [authType, setAuthType] = useState(initialValue || 'MAIN');

  useEffect(() => {
    setAuthType(initialValue || 'FIXED');
  }, [initialValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setAuthType(value);
    onChange(value); // Propagate the selected value to the parent component
  };

  return (
    <FormControl fullWidth >
      <InputLabel id="auth-type-label" style={{marginTop:10}}>User Count  Type</InputLabel>
      <Select
        labelId="auth-type-label"
        id="auth-type-select"
        value={authType}
        label="User Count Type"
        onChange={handleChange}
      >
        <MenuItem value="FIXED" style={{color:blue}}>FIXED</MenuItem>
        <MenuItem value="FLEXI" style={{color:yellow}}>FLEXI</MenuItem>
      </Select>
    </FormControl>
  );
}

export default UserCountTypeSelection;
