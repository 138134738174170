import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase-config'; // Ensure this path is correct
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Menu, MenuItem, TablePagination } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {fetchAllAppTypes,deleteAppType,isAppTypeUsed} from '../../dbfunc/AppTypesDbFunc';

function AppTypeListData({ onMenuClicked }) {
  const [apptypes, setApptypes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAppType, setSelectedAppType] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchAppTypes();
  }, []);
  const fetchAppTypes = async () => {
    /*()
    const querySnapshot = await getDocs(collection(db, "apptypes"));
    setApptypes(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    */
    const alldocs = await fetchAllAppTypes();
    if(alldocs.success){
      setApptypes(alldocs.appTypes);
    } else {
      console.log('Error fetching app types:', alldocs.error);
    }
  };

  const handleDelete = async (apptype) => {
    console.log('Delete clicked', apptype);
    const exists = await isAppTypeUsed(apptype.apptype);
    if(exists){
      console.log('App type is in use and cannot be deleted');
      alert('App type is in use and cannot be deleted');
      return
    }
    setSelectedAppType(apptype);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    console.log('Delete confirmed:', selectedAppType);
    setOpenDialog(false);
    const response = await deleteAppType(selectedAppType.apptype);
    if(response.success){
      fetchAppTypes();
    } else {
      console.log('Error deleting app type:', response.error);
      alert('Error deleting app type:', response.error);
    }
    /*
    await deleteDoc(doc(db, "apptypes", selectedAppType));
    setOpenDialog(false);
    const newSnapshot = await getDocs(collection(db, "apptypes"));
    setApptypes(newSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    */
  };
  const handleRowClick = (apptype) => {
    console.log('Row clicked:', apptype);
    setSelectedAppType(apptype);
  }
  return (
    <>
      <div>App Type Master </div>
      <TableContainer component={Paper} sx={{ maxHeight: '650px', overflowY: 'auto' }}>
        <Table sx={{ minWidth: 650 }} aria-label="apptype master table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'purple' }}>
              {["Apptype", "Description", "Enabled", "Log URL", "Other Data"].map((header) => (
                <TableCell key={header} align="center" sx={{ color: 'white', fontWeight: 'bold' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {apptypes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((apptype) => (
              <TableRow
                key={apptype.id}
                sx={{ '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                hover
                style={{ cursor: 'pointer' }}
              >
                <TableCell component="th" scope="row">{apptype.apptype}</TableCell>
                <TableCell>{apptype.des}</TableCell>
                <TableCell>{apptype.enabled ? 'Yes' : 'No'}</TableCell>
                <TableCell>{apptype.logurl}</TableCell>
                <TableCell>{apptype.other_data}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedAppType(apptype);
                  }}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem onClick={() => onMenuClicked('edit', apptype)}>Edit</MenuItem>
                    <MenuItem onClick={() => handleDelete( apptype)}>Delete</MenuItem>
                    <MenuItem onClick={() => onMenuClicked('features', apptype)}>Features</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageFeatures={[5, 10, 25, { label: 'All', value: -1 }]}
          component="div"
          count={apptypes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0); // Reset page to zero when changing the number of rows per page
          }}
          showLastButton={true}
          showFirstButton={true}
          sx={{ mr: '20px', display: 'flex', justifyContent: 'space-between' }}          
        />
      </TableContainer>
      <Fab color="primary" aria-label="refresh" sx={{ position: 'fixed', bottom: 16, left: 16 }} onClick={() => fetchAppTypes()}>
        <RefreshIcon />
      </Fab>      
      <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => onMenuClicked('add', null)}>
        <AddIcon />
      </Fab>
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message={`Are you sure you want to delete this app type: ${selectedAppType?.apptype}?`}
      />
    </>
  );
  
}

export default AppTypeListData;
