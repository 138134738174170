import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Box,Checkbox,FormControlLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { updateServerAuth, addServerAuth, checkServerAuthExists } from '../../dbfunc/ServerAuthDbFunc'; // Import the functions you wrote
import ClientsLookup from '../Clients/ClientsLookup';
import { ProcActKey,fetchLicData } from '../../js/LicFunc';
import SalesTeamLookup from '../Common/SalesTeamLookup';
import AuthTypeSelection from '../Common/AuthTypeSelection';
import AppInstallType from '../Common/AppInstallType';
import { saveAs } from 'file-saver';
import { formatDate } from '../../dbfunc/genfunc';
import ServerTypeSelection from '../Common/ServerTypeSelection';
import UserCountTypeSelection from '../Common/UserCountTypeSelection';
// import { FuncUpdateAuthListData } from '../../store/FuncUpdateAuthListData';
import { setList,resetList } from '../../store/AuthListStore'
const lic_features = ['ONE-TIME', 'YEARLY', 'MONTHLY', 'DAILY', 'TRIAL', 'ONCE', 'CHILD', 'NONE'];
const ServerAuthEntry = ({ backClicked, entryMode, serverAuthData, }) => {
    const isEditing = entryMode === "edit";
    const [clientLookupOpen, setClientLookupOpen] = useState(false);
    const [salesTeamLookupOpen, setSalesTeamLookupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        serverid: '',
        clientid: '',
        client_name: '',
        client_name2: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        enabled: 'Y',
        grace_period: '',
        location_des: '',
        locid: '',
        mac_addr: '',
        port_no: '',
        serverKey: '',
        userCount: '',
        userCountType: 'FIXED',
        entry_date: '',
        start_date: '',
        valid_until: '',
        valid_days: '',
        grace_period: '',
        license_type: '',
        install_type: 'VALIDATE',
        remarks: '',
        admin_pwd: '',
        admin_userid: '',
        salesteam: '',
        allowSameIp: 'N',
        authType:'',
        authUrl: '',
        notifyType: '',
        notifyUrl: '',
        serverType: '',
        wsBaseUrl: '',
        apiBaseUrl: '',
    });

    useEffect(() => {
        var sdata =  formData 
        console.log("server data ",serverAuthData)
        if (isEditing) {
            sdata = serverAuthData;
            if(serverAuthData.salesteam === undefined ) {
                sdata.salesteam = ''
            }
            if(serverAuthData.authType === undefined) {
                sdata.authType = ""
            }
            if(serverAuthData.start_date === undefined) {
                sdata.start_date = ""
            }
            if(serverAuthData.install_type === undefined) {
                sdata.install_type = "VALIDATE"
            }
            if(serverAuthData.authType === undefined) {
                sdata.authType = ""
            }
            if(serverAuthData.authUrl === undefined) {
                sdata.authUrl = ""
            }
            if(serverAuthData.serverType === undefined) {
                sdata.serverType = ""
            }
            if(serverAuthData.notifyType === undefined) {
                sdata.notifyType = ""
            }
            if(serverAuthData.notifyUrl === undefined) {
                sdata.notifyUrl = ""
            }
            if(serverAuthData.wsBaseUrl === undefined) {
                serverAuthData.wsBaseUrl = ""
            }
            if(serverAuthData.apiBaseUrl === undefined) {
                serverAuthData.apiBaseUrl = ""
            }
            if(serverAuthData.userCountType === undefined) {
                serverAuthData.userCountType = "FIXED"
            }
        } else {
            sdata.start_date = formatDate(new Date());
        }
        console.log("sdata ",sdata)
        setFormData(sdata);
    }, [serverAuthData, isEditing]);
    const updateServerMasterList =() => {
        resetList();
    }
    const handleChange = (event) => {
        const { name, type, checked } = event.target;
        const value = type === 'checkbox' ? (checked ? 'Y' : 'N') : event.target.value;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleAuthTypeChange = (value) => {
        console.log("authtype change ",value)
        setFormData({...formData,authType:value})
    }
    const installTypeChange = (value) => {
        console.log("Install type change ",value)
        setFormData({...formData,install_type:value})
      }
      const serverTypeChange = (value) => {
        console.log("Server type change ",value)
        setFormData({...formData,serverType:value})
        }
        const userCountTypeChanged = (value) => {
            console.log("user count type change ",value)
            setFormData({...formData,userCountType:value})
        }
    const handleBack = () => {
        // navigate('/AppCodeMasterList'); // Ensure the path is correct based on your routing setup
        backClicked("table");
      };
    
    const handleSubmit = async (event) => {
        event.preventDefault(); 
        if(formData.serverid === "" || formData.clientid === "" || formData.client_name === "" ){
            alert("srver id,client id,client name empty!")
            return
        }
        if(formData.contact_name === "" || formData.contact_phone === "" || formData.contact_email === "" || formData.enabled === "") { 
           alert("contact name,contact phone,contact email,enabled empty!")
              return
        }
        if (formData.grace_period === ""  || formData.locid === "" ) {
            alert("grace period,loc empty")
            return
        }
        if(formData.locid === "" ) {
            alert("locid empty!")
            return
        }
        if(formData.install_type === "") {
            alert("Install type empty!")
            return
        }
        if( formData.admin_pwd === "" || formData.admin_userid === ""  
           ||  formData.entry_date === ""  || formData.salesteam === ""
           || formData.start_date === ""  )
           {
                alert("admin pwd,admin userid,entry date,salesteam,start date,valid until empty!")
                return;
              return;
           }
           if(formData.authType === "" ) {
              alert("Auth type empty!")
              return
           }

        /*
        const startDate = new Date(formData.start_date);
        const validUntil = new Date(formData.valid_until);
        
        // Calculate the difference in milliseconds
        const differenceMs = Math.abs(validUntil - startDate);
        
        // Convert milliseconds to days
        const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));        
        if(differenceDays < 0){
            alert('Valid until date should be greater than start date');
            return;
        }
        setFormData({
            ...formData,
            valid_days: differenceDays,
        });
        */
        try {
            if (isEditing) {
                setIsLoading(true);
                const updateResult = await updateServerAuth(serverAuthData.id, formData);
                setIsLoading(false);
                if (!updateResult.success) {
                    alert('Failed to update the document. Please try again.');
                    return;
                }
                alert('Document updated successfully!');
                updateServerMasterList();
            } else {
                setIsLoading(true);
                const checkResult = await checkServerAuthExists(formData.serverid);
                if (checkResult.exists) {
                    alert('ServerAuth ID already exists.');
                    setIsLoading(false);
                    return;
                }
                const addResult = await addServerAuth(formData.serverid, formData);
                setIsLoading(false);
                if (!addResult.success) {
                    alert('Failed to add new document. Please try again.');
                    return;
                }
                alert('Document added successfully!');
                updateServerMasterList();
            }
            backClicked();
        } catch (error) {
            console.error('Unexpected error:', error);
            alert('An unexpected error occurred. Please try again.');
        }
    };
    const exportLicKeyData = async () => {
        /*
        const blob = new Blob([data], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fname;
        a.click();
        */

        try {
            const payload = {
                Serverid: serverAuthData.serverid,
                MechineSerial: formData.mac_addr,
                LicKey: formData.serverKey
            };
            setIsLoading(true);
            const data = await fetchLicData(payload);
            console.log('file got file data',data);
            saveAs(new Blob([data]), 'license.lic');            
            setIsLoading(false);
            // Handle the data received from the API
        } catch (error) {
            // Handle errors
            console.error(error.message);
        }
    };    
    const GetLicenseData = async () => {
        try {
            const payload = {
                Serverid: serverAuthData.serverid,
                MechineSerial: formData.mac_addr
            };
            setIsLoading(true);
            const result = await ProcActKey(payload);
            setIsLoading(false);
            console.log('result',result);
            if(result.Success !== "TRUE") {
                alert('Failed to get the key. Please try again.');
            } 
            setFormData({
                ...formData,
                serverKey: result.LicKey,
            });
            // setResponse(result);
        } catch (error) {
            console.error('Error:', error);
            // setResponse(null);
        }
    };
    const handleClientSelect = (c) => {
        console.log('Selected client:', c);
        setFormData({...formData, clientid: c.clientid, client_name: c.client_name});
        /*
        setFormData({...formData, client_name: c.client_name});
        setFormData({...formData, contact_name: c.contact_name});
        setFormData({...formData, contact_phone: c.contact_phone});
        setFormData({...formData, contact_email: c.email_addr});
        */
        setClientLookupOpen(false); // Close dialog after selection
      };  
    const handleSalesTeamSelect = (c) => {
        console.log('Selected sales team:', c);
        if(c.enabled !== 'Y'){
            alert('Sales Team is not enabled');
            return;
        }
        setFormData({...formData, salesteam: c.teamcode});
        setSalesTeamLookupOpen(false); // Close dialog after selection
      };
  
    return (
        <Container maxWidth="sm">
            <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
                Auth Server Entry
            <TextField
                label="Server ID"
                name="serverid"
                value={formData.serverid}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mt: 2, mb: 2 }}
            />
            <TextField
                label="Client ID"
                name="clientid"
                value={formData.clientid}
                onChange={handleChange}
                onClick={() => setClientLookupOpen(true)} // Open dialog on text field click
                readOnly          
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}

            />
            <TextField
                label="Client Name"
                name="client_name"
                value={formData.client_name}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <TextField
                label="Client Name 2"
                name="client_name2"
                value={formData.client_name2}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <TextField
                label="Contact Name"
                name="contact_name"
                value={formData.contact_name}
                onChange={handleChange}
                fullWidth
                sx={{ mb: 2 }}
                variant="outlined"
            />
            <TextField
                label="Contact Phone"
                name="contact_phone"
                value={formData.contact_phone}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <TextField
                label="Contact Email"
                name="contact_email"
                value={formData.contact_email}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <FormControlLabel
            control={
                <Checkbox
                checked={formData.enabled === 'Y'}
                onChange={handleChange}
                name="enabled"
                />
            }
            label="Enabled"
            />
            <TextField
                label="Grace Period"
                name="grace_period"
                value={formData.grace_period}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <TextField
                label="Location Description"
                name="location_des"
                value={formData.location_des}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <TextField
                label="Location ID"
                name="locid"
                value={formData.locid}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <TextField
                label="Websocket Base Url"
                name="wsBaseUrl"
                value={formData.wsBaseUrl}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <TextField
                label="API Base Url"
                name="apiBaseUrl"
                value={formData.apiBaseUrl}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
        <ServerTypeSelection initialValue={formData.serverType}  onChange={serverTypeChange}  />        
            <TextField
                label="Port Number"
                name="port_no"
                value={formData.port_no}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 ,marginTop:2}}
            />
            <TextField
                label="MAC Address"
                name="mac_addr"
                value={formData.mac_addr}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <TextField
                label="Server Key"
                name="serverKey"
                value={formData.serverKey}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                readOnly
                sx={{ mb: 2 }}
            />
            <UserCountTypeSelection initialValue={formData.userCountType}  onChange={userCountTypeChanged}  />        

            <TextField
                label="User Count"
                name="userCount"
                value={formData.userCount}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2,mt:2 }}
            />
            <TextField
                label="Start Date"
                name="start_date"
                value={formData.start_date}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}          
                sx={{ mb: 2 }}

            />
            <TextField
                label="Valid Until"
                name="valid_until"
                value={formData.valid_until}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}          
                sx={{ mb: 2 }}
            />
            <TextField
                label="valid Days"
                name="valid_days"
                value={formData.valid_days}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                readOnly
                sx={{ mb: 2 }}
            />
            {/*
       <InputLabel id="license-type-label">License Type</InputLabel>
            <Select
                labelId="license-type-label"
                id="license-type-select"
                name="license_type"
                value={formData.license_type} // Bind value to formData.license_type
                onChange={handleChange}
            >
                {lic_features.map(feature => (
                    <MenuItem key={feature} value={feature}>
                        {feature}
                    </MenuItem>
                ))}
            </Select>    
            */}
            <TextField
                label="Start Date"
                name="start_date"
                value={formData.start_date}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}          
                sx={{ mb: 1 }}
            />
                

        <AuthTypeSelection initialValue={formData.authType}  onChange={handleAuthTypeChange}/>
        <AppInstallType initialValue={formData.install_type}  onChange={installTypeChange} />        

            <TextField
                label="Remarks"
                name="remarks"
                value={formData.remarks}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2,marginTop:1 }}
            />

            <TextField
                label="Allow Same IP"
                name="allowSameIp"
                value={formData.allowSameIp}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <TextField
                label="Admin User ID"
                name="admin_userid"
                value={formData.admin_userid}
                onChange={handleChange}
                fullWidth
                sx={{ mb: 2 }}
                variant="outlined"
            />
            <TextField
                label="Admin Password"
                name="admin_pwd"
                value={formData.admin_pwd}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />

            <TextField label="Sales Team" 
                name="salesteam" value={formData.salesteam}
                onChange={handleChange} 
                readOnly
                onClick={() => setSalesTeamLookupOpen(true)} 
                fullWidth variant="outlined" sx={{ mb: 2 }} />
            <TextField
                label="Entry Date"
                name="entry_date"
                value={formData.entry_date}
                onChange={handleChange}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}          
                sx={{ mb: 2 }}
                fullWidth
                variant="outlined"
            />
            <ClientsLookup open={clientLookupOpen} onClose={() => setClientLookupOpen(false)} onSelect={(handleClientSelect)} />
            <SalesTeamLookup open={salesTeamLookupOpen} onClose={() => setSalesTeamLookupOpen(false)} onSelect={(handleSalesTeamSelect)} />
            {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        )}

               <Button variant="contained" color='secondary' onClick={GetLicenseData} sx={{mb:1}}>Get Key</Button>        
               <Button variant="contained" color='secondary' onClick={exportLicKeyData} sx={{mb:1}}>Export Lic</Button>        
                <Button type="submit" variant="contained" sx={{ mb: 1 }}>Save</Button>
                <Button variant="contained" color='primary' onClick={handleBack} sx={{mb:2}}>Back</Button>        
            </Box>
        </Container>
    );
};

export default ServerAuthEntry;
