import { doc, setDoc, getDoc, getDocs, collection,where,query,deleteDoc } from 'firebase/firestore';

import { db } from '../firebase-config'; // Adjust this import as necessary

// Function to fetch all apps for a specific serverid
export const fetchAllServerAuths = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'serverauth'));
        let serverauths = [];
        querySnapshot.forEach((doc) => {
            serverauths.push({ id: doc.id, ...doc.data() });
        });
        return { success: true, serverauths };
    } catch (error) {
        console.error("Error fetching serverauths:", error);
        return { success: false, error: error };
    }
};


export const fetchAllServerApps = async (serverid) => {
    try {
        // Path to the nested collection
        const serverAuthRef = doc(db, 'serverauth', serverid);
        const appsRef = collection(serverAuthRef, 'apps');
        const querySnapshot = await getDocs(appsRef);
        let apps = [];
        querySnapshot.forEach((doc) => {
            apps.push({ id: doc.id, ...doc.data() });
        });
        return { success: true, apps:apps }; // Return an object with a success status and the array of apps
    } catch (error) {
        console.error("Error fetching apps:", error);
        return { success: false, error: error }; // Return error information for further handling
    }
};




export const addServerApp = async (serverid, appcode, appData) => {
    try {
        const appRef = doc(collection(db, 'serverauth', serverid, 'apps'), appcode);
        const docSnap = await getDoc(appRef);
        if (docSnap.exists()) {
            return { success: false, message: 'App code already exists' };
        }

        await setDoc(appRef, appData);
        let trimmedId = serverid.trim()
        let trimmedappcode = appcode.trim()
        let serverappkey = trimmedId+"_"+trimmedappcode
        console.log("server app key",serverappkey)
        const servappRef = doc(db, 'serverauthapps', serverappkey);
        // here we add it twice to two different collections, for searching purpose
        await setDoc(servappRef, appData);
        return { success: true, message: 'App added successfully' };
    } catch (error) {
        console.error("Error adding app:", error);
        return { success: false, error: error };
    }
};

/*
export const addServerAppFeature = async (serverid, appcode, featureData) => {
    try {
        const appRef = doc(collection(db, 'serverauth', serverid, 'apps'), appcode);
        const docSnap = await getDoc(appRef);
        if (docSnap.exists()) {
            return { success: false, message: 'App code already exists' };
        }
        await setDoc(appRef, appData);
        return { success: true, message: 'App added successfully' };
    } catch (error) {
        console.error("Error adding app:", error);
        return { success: false, error: error };
    }
};
*/
export const updateServerApp = async (serverid, appcode, appData) => {
    try {
        const appRef = doc(collection(db, 'serverauth', serverid, 'apps'), appcode);
        await setDoc(appRef, appData, { merge: true });

        let trimmedId = serverid.trim()
        let trimmedappcode = appcode.trim()
        let serverappkey = trimmedId+"_"+trimmedappcode
        console.log("server app key",serverappkey)
        const servappRef = doc(db, 'serverauthapps', serverappkey);
        await setDoc(servappRef, appData,{merge:true});
        return { success: true, message: 'App updated successfully' };
    } catch (error) {
        console.error("Error updating app:", error);
        return { success: false, error: error };
    }
};

export const checkServerAppExists = async (serverid, appcode) => {
    try {
        const appRef = doc(collection(db, 'serverauth', serverid, 'apps'), appcode);
        const docSnap = await getDoc(appRef);
        return { exists: docSnap.exists(), success: true, message: docSnap.exists() ? 'App exists' : 'App does not exist' };
    } catch (error) {
        console.error("Error checking if app exists:", error);
        return { success: false, error: error, exists: false };
    }
};

// delete server app
export const deleteServerApp = async (serverid, appcode) => {
    try {
        const appRef = doc(collection(db, 'serverauth', serverid, 'apps'), appcode);
        await deleteDoc(appRef);
        return { success: true, message: 'App deleted successfully' };
    } catch (error) {
        console.error("Error deleting app:", error);
        return { success: false, error: error };
    }
};


// delete all serverappfeature
export const deleteAllServerAppFeatures = async (serverid, appcode) => {
    console.log('deleteAllServerAppFeatures:', serverid, appcode);
    try {
        const q = query(collection(db, 'serverauth', serverid, 'serverappfeatures'), where("appcode", "==", appcode));
        const querySnapshot = await getDocs(q);

        let deletionErrors = []; // Array to store potential deletion errors

        await Promise.all(querySnapshot.docs.map(async (doc) => {
            console.log('Deleting server app feature:', doc.id);
            try {
                await deleteDoc(doc.ref);
            } catch (error) {
                deletionErrors.push(`Error deleting document ${doc.id}: ${error}`);
            }
        }));

        if (deletionErrors.length > 0) {
            console.error('Errors encountered during deletion:', deletionErrors);
            // You may choose to return a more specific error message here
            return { success: false, message: 'Errors encountered during deletion' };
        } else {
            return { success: true, message: 'App features deleted successfully' };
        }
    } catch (error) {
        console.error("Error deleting app features:", error);
        return { success: false, error: error };
    }
};

